import { Button, Card, Container, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import brightensportLogo from '../../assets/logo_brightensport_tuerkis.svg';
import { LoginRoute } from "../../constants";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        minHeight: window.innerHeight,
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center'
    },
    paper: {
        margin: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    card: {
        borderRadius: 10
    },
    label: {
        marginTop: theme.spacing(1),
        textAlign: 'center',
    },
    labelBold: {
        marginTop: theme.spacing(1),
        fontWeight: 'bold',
        textAlign: 'center',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    logo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        maxWidth: '40%',
        marginBotton: 10,
    },
}));

const ForgotPaasswordSuccess = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();

    const handleLogInClick = () => {
        history.push(LoginRoute);
    };

    return (
        <Container className={classes.root} component="main" maxWidth="xs">
            <Card className={classes.card}>
                <div className={classes.paper}>
                    <img className={classes.logo} alt="brightensport logo" src={brightensportLogo}/>
                    <Typography className={classes.labelBold} variant="body1" align="center">
                        {t("forgot_password_success_label_title")}
                    </Typography>
                    <Typography className={classes.label} variant="body2" align="center">
                        {t("forgot_password_success_label_description")}
                    </Typography>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={handleLogInClick}
                    >
                        {t("forgot_password_success_cta")}
                    </Button>
                </div>
            </Card>
        </Container>
    );
};

export default ForgotPaasswordSuccess;