import { useState, useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardActions, CardContent, Divider, Button, Grid, TextField, CircularProgress } from '@material-ui/core';
import { Alert } from '@mui/material';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';

import axios from 'axios';
import { authMiddleWare } from '../util/Auth';
import * as Constants from '../constants';
import clsx from 'clsx';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid'
import deLocale from '@fullcalendar/core/locales/de';
import interactionPlugin from '@fullcalendar/interaction';
import { parseISO } from 'date-fns';

import Crop from './Crop';
import { getCroppedImg } from './Crop';
import LoadingScreen from './LoadingScreen';

const useStyles = makeStyles( (theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        width: '75%', 
        marginLeft: '12%',
    },
    root: {},
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    calendarContainer: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
        margin: 20,
    },
    mobileCalendarContainer: {
        display: 'block',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    details: {
        display: 'flex'
    },
    avatar: {
        height: 150,
        width: 150,
        flexShrink: 0,
        flexGrow: 0
    },
    locationText: {
        paddingLeft: '15px'
    },
    buttonProperty: {
        position: 'absolute',
        top: '50%'
    },
    uiProgress: {
        position: 'fixed',
        zIndex: '1000',
        height: '31px',
        width: '31px',
        top: '35%'
    },
    progress: {
        position: 'absolute'
    },
    uploadButton: {
        marginLeft: '8px',
        margin: theme.spacing(1)
    },
    customError: {
        color: 'red',
        fontSize: '0.8rem',
        marginTop: 10,
        marginLeft: '8px',
        margin: theme.spacing(1)
    },
    successMessage: {
        color: theme.palette.primary.main,
        fontSize: '0.8rem',
        marginTop: 10,
        marginLeft: '8px',
        margin: theme.spacing(1)
    },
    submitButton: {
        textAlign: "center",
        marginTop: '10px',
        marginRight: '10px'
    }
}));

const Profile = ({ handleManagePaymentClick, updateParentPriceSet }) => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [avatarSource, setAvatarSource] = useState('');
    const [credentials, setCredentials] = useState('');
    const [description, setDescription] = useState('');
    const [userType, setUserType] = useState('');
    const [sportName, setSportName] = useState('');
    const [availableSlots, setAvailableSlots] = useState([]);
    const [uiLoading, setUiLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [imageError, setImageError] = useState(false);
    const [imageSuccess, setImageSuccess] = useState(false);
    const [saveDetailsError, setSaveDetailsError] = useState(false);
    const [saveDetailsSuccess, setSaveDetailsSuccess] = useState(false);
    const [priceError, setPriceError] = useState(false);
    const [renderCrop, setRenderCrop] = useState(false);
    const [sessionPrice, setSessionPrice] = useState();
    const [priceAvailable, setPriceAvailable] = useState(false);
    const [src, setSrc] = useState(null);
    const [stripeOnboardingComplete, setStripeOnboardingComplete] = useState(true);

    const [crop, setCrop] = useState({   
        aspect: 1 / 1,
        unit: '%',
        x: 25,
        y: 25,
        width: 50
    });
    const imgRef = useRef(null);

    useEffect( () => {
        authMiddleWare(history);
        const authToken = localStorage.getItem(Constants.AuthTokenKey);
        axios.defaults.headers.common = {Authorization: `${authToken}`};
        axios
            .get(process.env.REACT_APP_BASE_URL + Constants.UserEndpoint)
            .then( (response) => {
                setFirstName(response.data.userCredentials.firstName);
                setLastName(response.data.userCredentials.lastName);
                setCredentials(response.data.userCredentials.credentials);
                setDescription(response.data.userCredentials.description);
                setUserType(response.data.userCredentials.userType);
                setSportName(response.data.userCredentials.sportName);
                setAvatarSource(response.data.userCredentials.avatar);
                let slots = response.data.userCredentials.availableSlots;
                setAvailableSlots( slots ? slots : []);
                setUiLoading(false);
                if (response.data.userCredentials.sessionPrice) {
                    setSessionPrice(response.data.userCredentials.sessionPrice);
                    setPriceAvailable(true);
                }
                if (response.data.userCredentials.userType === "pro") {
                    if (response.data.userCredentials.stripeAccountId) {
                        verifyStripeAccountStatus();
                    } else {
                        setStripeOnboardingComplete(false);
                    }
                    checkOnboardingAndRenderCalendar();
                }
            })
            .catch( (err) => {
                if (err.response && err.response.status === Constants.Forbidden) {
                    history.push(Constants.LoginRoute);
                }
                console.error(err);
                // TODO: set error message here?
            });
    }, []);

    /**
     * THIS IS HORESHIT, HANDLE IN A MORE GRACEFUL WAY WITH PROPS
     */
    const verifyStripeAccountStatus = () => {
        axios
            .get(process.env.REACT_APP_BASE_URL + Constants.StripeUserAccount)
            .then((account) => {
                if (!account.data.payouts_enabled) {
                    // onboarding not completed, show banner
                    setStripeOnboardingComplete(false);
                }
            })
            .catch((error) => {
                console.error("Error occured when retrievign account");
            });
    };

    const handleCropChange = (newCrop) => {
        setCrop(newCrop);
    }; 

    const handleImageLoaded = useCallback((image) => {
        imgRef.current = image;
    }, []);

    const handleChange = (event) => {
        const value = event.target.value;
        switch (event.target.name) {
            case "firstName":
                setFirstName(value);
                break;
            case "lastName":
                setLastName(value);
                break;
            case "credentials":
                setCredentials(value);
                break;
            case "description":
                setDescription(value);
                break;
            case "price":
                console.log(value);
                setSessionPrice(value);
                isNaN(value) || value.trim() === ""  ? setPriceError(true) : setPriceError(false);
                break;
            default:
                break;
        };
    };

    const handleImageChange = (e) => {
        setImageSuccess(false);
        setImageError(false);
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>  setSrc(reader.result));
            reader.readAsDataURL(e.target.files[0]);
            setRenderCrop(true);
        }
    };

    const profilePictureHandler = (event) => {
        event.preventDefault();
        setUiLoading(true);
        authMiddleWare(history);
        setImageError(false);
        setImageSuccess(false);
        getCroppedImg(imgRef.current, crop, "temp.jpeg")
            .then( (image) => {
                const authToken = localStorage.getItem(Constants.AuthTokenKey);
                axios.defaults.headers.common = { Authorization: `${authToken}`}
                let formData = new FormData();
                formData.append('image', image);
                return axios.post(process.env.REACT_APP_BASE_URL + '/user/image', formData, {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    })
            })
            .then( (response) => {
                console.log("response: ", response);
                setImageSuccess(true);
                setAvatarSource(response.data.avatar);
                setRenderCrop(false);
                setSrc(null);
                setUiLoading(false);
            })
            .catch((err) => {
                if (err.response && err.response.status === Constants.Forbidden) {
                    history.push(Constants.LoginRoute);
                }
                console.error(err);
                setUiLoading(false);
                setImageError(true);
            });
    };

    const updateFormValues = (event) => {
        event.preventDefault();
        setButtonLoading(true);
        authMiddleWare(history);
        const authToken = localStorage.getItem(Constants.AuthTokenKey);
        axios.defaults.headers.common = {Authorization: `${authToken}`};
        const formRequest = {
            firstName:firstName,
            lastName: lastName,
            credentials: credentials,
            description: description,
            availableSlots: availableSlots,
            sessionPrice: sessionPrice
        };
        axios
            .put(process.env.REACT_APP_BASE_URL + '/user', formRequest)
            .then(() => {
                if (sessionPrice) {
                    setPriceAvailable(true);
                    updateParentPriceSet(true);
                }
                setSaveDetailsSuccess(true);
                setButtonLoading(false);
            })
            .catch( (err) => {
                if (err.response && err.response.status === Constants.Forbidden) {
                    history.push('/login');
                }
                console.error(err);
                setSaveDetailsError(true);
                setButtonLoading(false);
            });
    };

    const createEventId = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
          });
    };

    const handleEventClick = (clickInfo) => {
        clickInfo.event.remove();
    };

    const handleSlotClick = (selectInfo) => {
        let calendarApi = selectInfo.view.calendar;
        calendarApi.unselect();
        const newEvent = {
            id: createEventId(),
            start: selectInfo.startStr,
            end: selectInfo.endStr
        }
        const start = parseISO(newEvent.start);
        const end = parseISO(newEvent.end);
        const lengthMinutes = Math.abs(end - start)/(1000 * 60);
        if (lengthMinutes > 30) {
            // that means it's more than one slot, call helper
            const slots = splitIntoStandardSlots(start, end);
            setAvailableSlots([...availableSlots, ...slots]);
            return;
        }
        setAvailableSlots([...availableSlots, newEvent]);
    };

    const splitIntoStandardSlots = (start, end) => {
        console.log(`start: ${start} end: ${end}`);
        let slots = [];
        let rollingStart = new Date(start);
        let tmp = new Date();
        while (rollingStart.getTime() < end.getTime()) {
            tmp.setTime(rollingStart.getTime() + 30 * 60 * 1000);
            slots.push({
                id: createEventId(),
                start: new Date(rollingStart),
                end: new Date(tmp)
            });
            console.log("slots in loop: ", slots);
            rollingStart.setTime(tmp.getTime());
        }
        console.log("slots: ", slots);
        return slots;
    };

    const handleEventRemoval = (removeInfo) => {
        setAvailableSlots(availableSlots.filter( slot => slot.id !== removeInfo.event.id ));
    };

    const checkOnboardingAndRenderCalendar = () => {
        if (userType === "ama") {
            return;
        }
        if (!stripeOnboardingComplete) {
            renderAlert("stripe");
            return;
        }
        if (!priceAvailable) {
            renderAlert("price");
            return;
        }
        return renderCalendar();
    };

    const renderCalendar = () => {
        return (
            <>
                <div className={classes.calendarContainer}>
                    <Card className={clsx(classes.root, classes)}>
                        <Divider/>
                        <CardContent>
                            <FullCalendar
                                plugins={[ timeGridPlugin, interactionPlugin ]}
                                initialView='timeGridWeek'
                                locale={deLocale}
                                editable={true}
                                selectable={true}
                                firstDay='1'
                                slotMinTime='06:00:00'
                                slotMaxTime='23:00:00'
                                eventClick={handleEventClick}
                                select={handleSlotClick}
                                events={availableSlots}
                                eventRemove={handleEventRemoval}
                                eventColor='#44D4BC'
                            />
                        </CardContent>
                        <Divider />
                        <CardActions />
                    </Card>
                </div>
                <div className={classes.mobileCalendarContainer}>
                    <Card className={clsx(classes.root, classes)}>
                        <Divider/>
                        <CardContent>
                            <FullCalendar
                                plugins={[ timeGridPlugin, interactionPlugin ]}
                                headerToolbar={{
                                    left: 'title',
                                    center: '',
                                    right: 'prev,next today',
                                }}
                                initialView='timeGridDay'
                                locale={deLocale}
                                editable={true}
                                selectable={true}
                                firstDay='1'
                                slotMinTime='06:00:00'
                                slotMaxTime='23:00:00'
                                eventClick={handleEventClick}
                                select={handleSlotClick}
                                events={availableSlots}
                                eventRemove={handleEventRemoval}
                                eventColor='#44D4BC'
                            />
                        </CardContent>
                        <Divider />
                        <CardActions />
                    </Card>
                </div>
            </>
        );
    };

    const renderAlert = (reason) => {
        return (
            <Alert 
                severity="error" 
                variant="filled"
                action={ reason === "stripe" &&
                    <Button color="inherit" size="small" variant="outlined" onClick={handleManagePaymentClick}>
                        {t("alert_connect_onboarding_cta")}
                        { buttonLoading && <CircularProgress size={20}/>}
                    </Button>
                }
            >
                {reason === "stripe" ? t("alert_connect_onboarding_message") : t("alert_price_not_set")}
            </Alert>
        );
    };

    if (uiLoading === true ) {
        return (
            <LoadingScreen/>
        );
    } else {
        return (
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Card className={clsx(classes.root, classes)}>
                    <CardContent>
                        <div className={classes.details}>
                            <Grid 
                                container
                                direction="row"
                                spacing={0}
                                justify="space-between"
                                alignItems="center"
                            >
                                <Grid item md={8} xs={12}>
                                    <Typography className={classes.locationText} gutterBottom variant="h4">
                                        {firstName} {lastName}
                                    </Typography>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        type="submit"
                                        size="small"
                                        disabled={!src}
                                        startIcon={<CloudUploadIcon/>}
                                        className={classes.uploadButton}
                                        onClick={(event) => profilePictureHandler(event)}
                                    >
                                        {t("settings_profile_button_upload_photo")}
                                    </Button>
                                    <input className={classes.uploadButton} type="file" onChange={(event) => handleImageChange(event)} />
                                    {imageError &&
                                        <Typography className={classes.customError}>
                                            {' '}
                                            {t("general_error")}
                                        </Typography>
                                    }
                                    {imageSuccess && 
                                        <Typography className={classes.successMessage}>
                                            {' '}
                                            {t("settings_profile_label_avatar_upload_success")}
                                        </Typography>
                                    }
                                </Grid>
                                <Grid item>
                                    <Avatar
                                        className={classes.avatar}
                                        src={avatarSource}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                        { renderCrop && <Crop src={src} crop={crop} onChange={handleCropChange} onImageLoaded={handleImageLoaded}/> }
                        <div className={classes.progress}/>
                    </CardContent>
                    <Divider/>
                </Card>

                <br />
                <Card className={clsx(classes.root, classes)}>
                    <form autoComplete="off" noValidate>
                        <Divider/>
                        <CardContent>
                            <Grid container spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t("settings_profile_label_first_name")}
                                        margin="dense"
                                        name="firstName"
                                        variant="outlined"
                                        value={firstName}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t("settings_profile_label_last_name")}
                                        margin="dense"
                                        name="lastName"
                                        variant="outlined"
                                        value={lastName}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                {userType === "pro" && 
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        multiline={true}
                                        label={t("settings_profile_label_credentials")}
                                        margin="dense"
                                        name="credentials"
                                        variant="outlined"
                                        value={credentials}
                                        onChange={handleChange}
                                    />
                                </Grid>}
                                { userType === "pro" &&
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t("settings_profile_label_description")}
                                        multiline={true}
                                        margin="dense"
                                        name="description"
                                        variant="outlined"
                                        value={description}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                }
                                { userType === "pro" &&
                                <Grid item md={6} xs={12}>
                                    <TextField
                                        error={priceError}
                                        fullWidth
                                        label={t("settings_profile_label_price")}
                                        multiline={true}
                                        margin="dense"
                                        name="price"
                                        variant="outlined"
                                        value={sessionPrice}
                                        onChange={handleChange}
                                        helperText={priceError && t("seetings_profile_label_price_error")}
                                    />
                                </Grid>
                                }
                            </Grid>
                        </CardContent>
                        <Divider />
                        <CardActions />
                    </form>
                </Card>
                <br />
                { checkOnboardingAndRenderCalendar() }
                <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    className={classes.submitButton}
                    onClick={ (event) => updateFormValues(event)}
                    disabled={
                        buttonLoading || !firstName || !lastName || priceError
                    }
                >
                    {t("settings_profile_button_cta")}
                    {buttonLoading && <CircularProgress size={30} className={classes.progress}/>}
                </Button>
                {saveDetailsError && <Typography className={classes.customError}>
                    {' '}
                    {t("general_error")}
                    </Typography>
                }
                 {saveDetailsSuccess && <Typography className={classes.successMessage}>
                    {' '}
                    {t("settings_profile_label_save_success")}
                    </Typography>
                }
                
                {/*
                    <Button
                        className={classes.submitButton}
                        onClick={()=> history.push('/')}
                    >
                        { t("settings_profile_button_cancel") }
                    </Button>
                 */}
           
            </main>
        );
    }
}

export default Profile;