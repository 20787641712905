import { useRef, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Grid } from '@material-ui/core';

// MUI Icons
import LineWeightIcon from '@mui/icons-material/LineWeight';
import UndoIcon from '@mui/icons-material/Undo';
import ClearIcon from '@mui/icons-material/Clear';
import GestureIcon from '@mui/icons-material/Gesture';
import RemoveIcon from '@mui/icons-material/Remove';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';

const useStyles = makeStyles( (theme) => ({
    toolboxContainer: {
        justifyContent: 'center',
        marginTop: '-50px',
    },
    selected: {
        color: theme.palette.primary.main
    }
}));

const Canvas = ({ options, player, editMode }) => {
    const classes = useStyles();
    const canvasRef = useRef(null);
    const [shouldDraw, setShouldDraw] = useState(false);
    const [canvas, setCanvas] = useState();
    const [context, setContext] = useState();
    const [penColor, setPenColor] = useState('#44D4BC');
    const [lineWidth, setLineWidth] = useState('4');
    const [lineWeightDisplay, setLineWeightDisplay] = useState(false);


    const [paths, setPaths] = useState([]);
    const [drawingType, setDrawingType] = useState('line');

    // ensuring we can access the previous state. React is know to group updates to state
    // for sake of efficiency, so if we just address the current state it's not guaranteed 
    // we access the most recent value.
    const previousPathsRef = useRef();

    useEffect( () => {
        previousPathsRef.current = paths;
    });

    useEffect( () => {
        const canvas = canvasRef.current;
        setCanvas(canvas);
        setContext(canvas.getContext('2d'));
    }, [player]);

    const freeHandDraw = (clientX, clientY) => {
        context.beginPath();
        if (previousPathsRef.current.length === 0) {
            // TODO: the fuck does this mean then? maybe related to the problem below?
            // NOTE: for freedraw when this happens adjacent paths connect for whatever reason 
            // maybe some shit happens and something gets popped and JS thinks the new object is the same as 
            // the old one and treats them as one and updates same?
            // NOTE: maybe JS swaps them when it encounters a similar property? happened when drawing with freehand over
            // rect
            return;
        }
        const previousPathObj = previousPathsRef.current[previousPathsRef.current.length - 1]; 
        if (!previousPathObj) {
            // TODO: because this one returns, that means this particular path will be considered
            // the same as an empty one and popped at onmouseup, that's why sometimes shapes disappear
            // why can previousPathObjShit be null?
            return;
        }
        const previousPath = previousPathObj.path;
        // TODO: apparently the shit below can also be undefined :()
        context.moveTo(previousPath[previousPath.length - 1].x, previousPath[previousPath.length - 1].y);
        // since with every mouse move we add another {x, y} to the array and adding a new object with the
        // new array, we pop the previous one.
        // TODO: find better way of doing that -> e.g. just replace the array of the last object?
        previousPathsRef.current.pop();

        const boundingRect = canvas.getBoundingClientRect();
        let coords = {x: clientX - boundingRect.left, y: clientY - boundingRect.top};
        let newPath = [...previousPath, coords];
        // basically building a new object, deconstructing the old array (without the last one, cause)
        // it was popper, and putting everything together
        setPaths([...previousPathsRef.current, {...previousPathObj, path: newPath}]);
        context.lineTo(coords.x, coords.y);
        context.stroke();
    };

    const drawLine = (clientX, clientY) => {
        context.clearRect(0, 0, canvas.width, canvas.height);
        const boundingRect = canvas.getBoundingClientRect();
        if (previousPathsRef.current.length === 0) {
            return;
        };

        const previousPathObj = previousPathsRef.current[previousPathsRef.current.length -1];
        if (!previousPathObj) {
            return;
        }
        const previousPath = previousPathObj.path;
        const newX = clientX - boundingRect.left;
        const newY = clientY - boundingRect.top;
        previousPathsRef.current.pop();
        setPaths([...previousPathsRef.current, {...previousPathObj, path: {...previousPath, newX: newX, newY: newY}}]);
        context.beginPath();
        context.moveTo(previousPath.x, previousPath.y);
        context.lineTo(newX, newY);
        context.stroke();
        drawPaths();
    };

    const drawRectangle = (clientX, clientY) => {
        context.clearRect(0, 0, canvas.width, canvas.height);
        const boundingRect = canvas.getBoundingClientRect();
        if (previousPathsRef.current.length === 0) {
            // TODO: the fuck does this mean then? maybe related to the problem below?
            return;
        }
        const previousPathObj = previousPathsRef.current[previousPathsRef.current.length - 1];
        if (!previousPathObj) {
            return;
        }
        // this is not an array, this is an object
        const previousPath = previousPathObj.path;
        const newWidth = clientX - previousPath.x - boundingRect.left;
        const newHeight = clientY - previousPath.y - boundingRect.top;

        previousPathsRef.current.pop();
        setPaths([...previousPathsRef.current, {...previousPathObj, path: {...previousPath, width: newWidth, height: newHeight}}]);
        
        context.beginPath();
        context.rect(previousPath.x, previousPath.y, newWidth, newHeight);
        context.stroke();
        drawPaths();
    };

    const drawEllipse = (clientX, clientY) => {
        context.clearRect(0, 0, canvas.width, canvas.height);

        context.beginPath();
        const boundingRect = canvas.getBoundingClientRect();
        if (previousPathsRef.current.length === 0) {
            // TODO: the fuck does this mean then? maybe related to the problem below?
            // NOTE: for ellipses seems to happen when boundaries of shapes cross each other. 
            // IN PARTICULAR WITH SHAPES: RECT AND ELLIPSE
            return;
        }
        const previousPathObj = previousPathsRef.current[previousPathsRef.current.length -1];
        if (!previousPathObj) {
            // TODO: because this one returns, that means this particular path will be considered
            // the same as an empty one and popped at onmouseup, that's why sometimes shapes disappear
            // why can previousPathObjShit be null?
            return;
        }
        const previousPath = previousPathObj.path;

        // radius cannot be negative:
        const newRadius = Math.abs(clientX - previousPath.x - boundingRect.left);
        previousPathsRef.current.pop();
        setPaths([...previousPathsRef.current, {...previousPathObj, path: {...previousPath, radius: newRadius}}]);
        context.arc(previousPath.x, previousPath.y, newRadius, 0, Math.PI * 2);
        context.stroke();
        drawPaths();
    };

    const handleMouseMove = (event) => {
        event.preventDefault();
        if (!shouldDraw) {
            return;
        }
        context.strokeStyle = penColor;
        context.lineWidth = lineWidth;
        context.lineCap = "rounded";

        // TODO: make nicer - use factory method?
        switch (drawingType) {
            case 'free':
                freeHandDraw(event.clientX, event.clientY);
                break;
            case 'line':
                drawLine(event.clientX, event.clientY);
                break;
            case 'rect':
                drawRectangle(event.clientX, event.clientY);
                break;
            case 'ellipse':
                drawEllipse(event.clientX, event.clientY);
                break;
            default:
                console.error("Invalid drawingType set");
        }
    };

    const drawPaths = () => {
        if (!paths) {
            return;
        }
        for (let i = 0; i < paths.length; i++) {
            const pathObj = paths[i];
            context.strokeStyle = pathObj.color;
            context.lineWidth = pathObj.lineWidth;
            context.beginPath();
            switch (pathObj.type) {
                case 'free':
                    const freeHandPath = pathObj.path;
                    for (let j = 0; j<freeHandPath.length; j++) {
                        if (j === freeHandPath.length - 1) {
                            continue;
                        }

                        context.moveTo(freeHandPath[j].x, freeHandPath[j].y);
                        context.lineTo(freeHandPath[j + 1].x, freeHandPath[j + 1].y);
                    }
                    break;
                case 'line':
                    const linePath = pathObj.path;
                    context.moveTo(linePath.x, linePath.y);
                    context.lineTo(linePath.newX, linePath.newY);
                    break;
                case 'rect':
                    const rectPath = pathObj.path;
                    context.rect(rectPath.x, rectPath.y, rectPath.width, rectPath.height);
                    break;
                case 'ellipse':
                    const ellipsePath = pathObj.path;
                    console.log("drawing ellipse: ", ellipsePath);
                    context.arc(ellipsePath.x, ellipsePath.y, ellipsePath.radius, 0, Math.PI * 2);
                    break;
                default:
                    console.error("Invalid drawingType provided");
            }
            context.stroke();
        }
    };

    const getInitialPathForShape = (clientX, clientY, drawingType) => {
        const boundingRect = canvas.getBoundingClientRect();
        switch (drawingType) {
            case 'free': 
                const freeHandPath = [{x: clientX - boundingRect.left, y:clientY - boundingRect.top}];
                console.log("returning path for free drawing: ", freeHandPath);
                return freeHandPath;
            case 'line':
                const linePath = {x: clientX - boundingRect.left, y: clientY - boundingRect.top};
                return linePath;
            case 'rect':
                const rectPath = {x: clientX - boundingRect.left, y: clientY - boundingRect.top, width: 0, height: 0};
                console.log("returning path for rectangle: ", rectPath);
                return rectPath;
            case 'ellipse':
                const ellipsePath = {x: clientX - boundingRect.left, y: clientY - boundingRect.top, radius: 0, sAngle: 0, eAngle: Math.PI * 2};
                console.log("returning path for ellipse:", ellipsePath);
                return ellipsePath;
            default:
                console.error("Unknown drawing type provided");
        }
    };

    const handleMouseDown = (event) => {
        if (!editMode) {
            return;
        }

        event.preventDefault();
        setShouldDraw(true);
        let newPath = {
            key: Math.floor(player.currentTime()),
            path: getInitialPathForShape(event.clientX, event.clientY, drawingType),
            type: drawingType,
            color: penColor,
            lineWidth: lineWidth,
        };
        setPaths([...previousPathsRef.current, newPath]);
    };

    const handleEmptyShape = () => {
        paths.pop();
        if (player.paused()) {
            // start playing
            player.play();
        } else {
            // pause
            player.pause();
        }
    }
    const handleMouseUp = (event) => {
        event.preventDefault();
        if (!editMode) {
            if (player.paused()) {
                // start playing
                player.play();
            } else {
                // pause
                player.pause();
            }
            return;
        }
        setShouldDraw(false);
        // TODO: in cases of ellipses or rectangles, if drawing one and go back till (0,0) this pops a path
        // and the draw method another - so shapes disappear?
        // drawing is over - check if there is any "single click" drawings and remove, based on current type
        switch (drawingType) {
            case 'free':
                if (paths[paths.length - 1].path.length === 1) {
                    handleEmptyShape();
                }
                break;
            case 'line':
                if (paths[paths.length - 1].path.x === paths[paths.length - 1].path.newX && paths[paths.length - 1].path.y === paths[paths.length - 1].path.newy) {
                    handleEmptyShape();
                }
                break;
            case 'rect':
                if (paths[paths.length -1].path.width === 0 && paths[paths.length -1].path.height === 0 ) {
                    handleEmptyShape();
                }
                break;
            case 'ellipse':
                if (paths[paths.length -1].path.radius === 0) {
                    handleEmptyShape();
                }
                break;
            default:
                console.error("Unknown drawingType provided");
        }
        // TODO: based on the type of drawing check and eliminate the empty ones
        console.log("current paths: ", paths);
    };

 
    
    const handleLineWidthChange = (event) => {
        setLineWidth(event.target.value);
    };
    
    const handlePenColorChange = (event) => {
        setPenColor(event.target.value);
    };

    const handleUndoClick = (event) => {
        context.clearRect(0, 0, canvas.width, canvas.height);
        previousPathsRef.current.pop();
        setPaths([...previousPathsRef.current]);
        drawPaths();
    };

    const handleClearClick = () => {
        context.clearRect(0, 0, canvas.width, canvas.height);
        setPaths([]);
    };

    const handleFreeHandClick = () => {
        setDrawingType('free');
    };

    const handleLineClick = () => {
        setDrawingType('line');
    };

    const handleRectClick = () => {
        setDrawingType('rect');
    };

    const handleEllipseClick = () => {
        setDrawingType('ellipse');
    };

    return (
        <div>
            {editMode && <Grid className={classes.toolboxContainer} container hide={true}>
                 {/* <label for="penColor">Color:</label> */}
                 <IconButton>

                    <input type="color" value={penColor} defaultValue={penColor} onChange={handlePenColorChange}/>

                 </IconButton>
                <IconButton aria-label="undo" onClick={handleUndoClick}>
                    <UndoIcon/>
                </IconButton>
                <IconButton aria-label="clear" onClick={handleClearClick}>
                    <ClearIcon/>
                </IconButton>
                <IconButton className={drawingType === "free" && classes.selected}  aria-label="free-hand-draw" onClick={handleFreeHandClick}>
                    <GestureIcon/>
                </IconButton>
                <IconButton className={drawingType === "line" && classes.selected} aria-label="line-draw" onClick={handleLineClick}>
                    <RemoveIcon/>
                </IconButton>
                <IconButton className={drawingType === "rect" && classes.selected} aria-label="rectangle-draw" onClick={handleRectClick}>
                    <CropSquareIcon/>
                </IconButton>
                <IconButton className={drawingType === "ellipse" && classes.selected} aria-label="ellipse-draw" onClick={handleEllipseClick}>
                    <CircleOutlinedIcon/>
                </IconButton>
               {/* <label for="lineWidth">Width: </label> */}
               <IconButton className={lineWeightDisplay && classes.selected} aria-label="line-weight" onClick={() => setLineWeightDisplay(!lineWeightDisplay)}> 
                    <LineWeightIcon/>
               </IconButton>
                {lineWeightDisplay && <input type="range" min="1" max="10" defaultValue={lineWidth} onChange={handleLineWidthChange}/>}
           
            </Grid> }
            <center>
                <canvas 
                    ref={canvasRef} 
                    onMouseDown={handleMouseDown} 
                    onMouseUp={handleMouseUp}
                    onMouseMove={handleMouseMove} 
                    width={options.width} 
                    height={options.height}
                />
            </center>
            
            
        </div>
    );
};

export default Canvas;