import { Card, Container, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import brightensportLogo from '../../assets/logo_brightensport_tuerkis.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        minHeight: window.innerHeight,
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
    },
    paper: {
        margin: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    card: {
        borderRadius: 10,
    },
    labelBold: {
        marginTop: theme.spacing(1),
        fontWeight: 'bold',
        textAlign: 'center',
    },
    label: {
        marginTop: theme.spacing(1),
        textAlign: 'center',
   },
   logo: {
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        maxWidth: '40%',
        marginBottom: 10,
   },
}));

const StripeCheckoutFailure = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Container className={classes.root} component='main' maxWidth='xs'>
            <Card className={classes.card}>
                <div className={classes.paper}>
                    <img className={classes.logo} alt="brightensport logo" src={brightensportLogo}/>
                    <Typography className={classes.labelBold} variant="body1">
                        {t("stripe_checkout_failure_label_title")}
                    </Typography>
                    <Typography className={classes.label} variant="body2">
                        {t("stripe_checkout_failure_label_body")}
                    </Typography>
                </div>

            </Card>
        </Container>
    );
};

export default StripeCheckoutFailure;