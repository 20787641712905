/**
 * All sorts of helper functions that we can use to validate user input
 */

export const isEmail = (email) => {
    const emailRegex = "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$";
    if (email.match(emailRegex)) return true;
    else return false;
};

export const isValidPassword = (password) => {
    // Passwords should be:
    // * at least 8 characters long
    // * at most 32 characters long
    // * contain at least one lowercase letter
    // * contain at least one uppercase letter
    // * container at least one number
    // * for now we leave the special characters out, but if we want we can add: (?=.*[A-Z])(?=.*[*.!@$%^&(){}[]:;<>,.?/~_+-=|\])
    const passwordRegexp = "^(?=.*[0-9])(?=.*[a-z]).{8,32}$";
    if (password.match(passwordRegexp)) return true;
    else return false;
};