import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import * as Constants from '../constants';

import { makeStyles } from '@material-ui/styles';
import { Alert, Button } from '@mui/material';

import Nav from './Nav';
import LogoutDialog from './LogOutDialog';
import Footer from './Footer';
import FeedbackRequest from './FeedbackRequest';
import Overview from './Overview';
import FAQ from './FAQ';
import Profile from './Profile';
import Sessions from './Sessions';
import ChangePasswordDialog  from './ChangePasswordDialog';
import { authMiddleWare } from '../util/Auth';
import axios from 'axios';

import LoadingScreen from './LoadingScreen';
import { CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles( (theme) => ({
    root: {
        flexGrow: 1,
        minHeight: window.innerHeight,
        display: 'flex',
        flexFlow: 'column',
    },
    uiProgress: {
        position: 'fixed',
        zIndex: '1000',
        height: '31px',
        width: '31px',
        top: '35%'
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    alertCta: {
        color:'white',
        marginLeft: '10px',
    },
}));

const Home = () => {
    const history = useHistory();
    const classes = useStyles();
    const { t } = useTranslation();
    const [mode, setMode] = useState('overview');
    const [uiLoading, setUiLoading] = useState(true);
    const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
    const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState(false);
    const [proAthleteSelected, setProAthleteSelected] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [bannerVisible, setBannerVisible] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [stripeOnboardingComplete, setStripeOnboardingComplete] = useState(true);
    const [priceSet, setPriceSet] = useState(true);

    useEffect( () => {
        authMiddleWare(history);
        const authToken = localStorage.getItem(Constants.AuthTokenKey);
        axios.defaults.headers.common = {Authorization: `${authToken}`}
        setUiLoading(true);
        axios
        .get(process.env.REACT_APP_BASE_URL + Constants.UserEndpoint)
        .then( (response) => {
            const userCredentials = response.data.userCredentials;
            let slots = userCredentials.availableSlots;
            setCurrentUser({
                id: userCredentials.id,
                firstName: userCredentials.firstName,
                lastName: userCredentials.lastName,
                credentials: userCredentials.credentials,
                description: userCredentials.description,
                userType: userCredentials.userType,
                sportName: userCredentials.sportName,
                stripeAccountId: userCredentials.stripeAccountId,
                slots: slots ? slots : []
            });
         
            setUiLoading(false);
            // Check if pro user, and if yes - if finished onboarding
            if (userCredentials.userType === "pro") {
                if (!userCredentials.sessionPrice) {
                    setPriceSet(false);
                    setBannerVisible(true);
                }
                if (userCredentials.stripeAccountId) {
                    verifyStripeAccountStatus(userCredentials.stripeAccountId);
                } 
            }
        })
        .catch( (err) => {
            if (err.response && err.response.status === Constants.Forbidden) {
                history.push(Constants.LoginRoute);
            }
            setUiLoading(false);
            console.error(err);
            // TODO: set error message here?
        });

    }, []);

    const verifyStripeAccountStatus = () => {
        axios
            .get(process.env.REACT_APP_BASE_URL + Constants.StripeUserAccount)
            .then((account) => {
                if (!account.data.payouts_enabled) {
                    // onboarding not completed, show banner
                    console.log(account);
                    setBannerVisible(true);
                    setStripeOnboardingComplete(false);
                    return Promise.resolve(null);
                } else {
                    return axios.put(`${process.env.REACT_APP_BASE_URL}${Constants.UserEndpoint}`, { display: true});
                }
            })
            .then(() => {
                
            })
            .catch((error) => {
                console.error("Error occured when retrievign account");
            });
    };

    const handleLogoutDialogClose = () => {
        setLogoutDialogOpen(false);
    };

    const handleChangePasswordDialogClose = () => {
        setChangePasswordDialogOpen(false);
    };

    // MENU

    const handleLogoClick = () => {
        setMode('overview');
    }

    const handleHomeClick = () => {
        setMode('overview');
    }
    const handleSessionsClick = () => {
        setMode('sessions');
    }

    const handleFAQClick = () => {
        window.open(Constants.FaqLink);
    }

    /**
     * Handle the click on "Edit Profile" menu item and display the edit profile details component
     */
    const handleEditProfileClick = () => {
        setMode('profile');
    };
    
    /**
     * Handle the click on the "Change Password" menu item and initiate password change process
     */
    const handleChangePasswordClick = () => {
        setChangePasswordDialogOpen(true);
    };

    const handleManagePaymentClick = () => {
        console.log("current user's stripe account: ", currentUser.stripeAccountId);
        setButtonLoading(true);
        axios
            .get(process.env.REACT_APP_BASE_URL + Constants.StripeConnectLoginLinkEndpoint)
            .then((response) => {
                console.log(response);
                setButtonLoading(false);
                window.open(response.data.loginLink);
            })
            .catch((error) => {
                setButtonLoading(false);
                console.error("An error occured when fetching login link: ", error);
            });
    };

    const handleCompleteOnboardingClick = () => {
        console.log("current user's stripe account: ", currentUser.stripeAccountId);
        setButtonLoading(true);
        axios
            .get(process.env.REACT_APP_BASE_URL + Constants.UserAccountLink)
            .then((response) => {
                console.log(response);
                setButtonLoading(false);
                window.open(response.data.url, "_self");
            })
            .catch((error) => {
                setButtonLoading(false);
                console.error("An error occured when fetching onboarding link: ", error);
            });
    };

    const handleSetPriceClick = () => {
        setMode('profile');
    };

    /**
     * Handle the click on the "Logout" menu item and display the confirmation dialog
     */
    const handleLogOutClick = () => {
        setLogoutDialogOpen(true);
    };

    // Helper functions
    /**
     * Perform logout: remove the AuthToken from local storage and display the login page
     */
    const handleLogOut = () => {
        localStorage.removeItem(Constants.AuthTokenKey);
        history.push(Constants.LoginRoute);
    };

    const handleSessionSubmit = () => {
        //setMode('sessions');
    }

        /**
     * Handling the click on "Feedabck Request" button on a card for a specific athlete, initiating the process for feedback request from this athlete.
     * @param {*} athlete 
     */
    const handleFeedbackRequestClick = (athlete) => {
        setProAthleteSelected(athlete);
        setMode('feedback');
    };

    const renderAlert = () => {
        if (stripeOnboardingComplete && priceSet) {
            return;
        }

        return (
            <Alert 
                        severity="error" 
                        variant="filled"
                        action={ 
                            <Button 
                                color="inherit" 
                                size="small" 
                                variant="outlined" 
                                disabled={buttonLoading}
                                onClick={!stripeOnboardingComplete ? handleCompleteOnboardingClick : handleSetPriceClick}>
                                { t("alert_connect_onboarding_cta") }
                                { buttonLoading && <CircularProgress className={classes.alertCta} size={15}/>}
                            </Button>
                        }
                    >
                        { stripeOnboardingComplete? t("alert_price_not_set") : t("alert_connect_onboarding_message") }
                    </Alert>
        );
    };

    const handlePriceUpdate = (priceSet) => {
        console.log("price is set :", priceSet);
        setPriceSet(priceSet);
    };

    const Components = Object.freeze({
        feedback: <FeedbackRequest pro={proAthleteSelected} handleSessionSubmit={handleSessionSubmit}/>,
        overview: currentUser && currentUser.userType === "ama" ? <Overview onRequestFeedbackClick={handleFeedbackRequestClick}/> : <Sessions currentUser={currentUser}/>,
        profile:  <Profile handleManagePaymentClick={handleManagePaymentClick} updateParentPriceSet={handlePriceUpdate}/>,
        sessions: currentUser && <Sessions currentUser={currentUser}/>,
        faq: <FAQ />
    });

    if (uiLoading) {
        return (
            <LoadingScreen/>
        );
    } else {
        return (
            <div className={classes.root}>
                <Nav 
                    currentUser={currentUser}
                    mode={mode}
                    stripeOnboardingComplete={stripeOnboardingComplete}
                    onLogoClick={handleLogoClick}
                    onHomeClick={handleHomeClick}
                    onSessionsClick={handleSessionsClick}
                    onFAQClick={handleFAQClick}
                    onEditProfileClick={handleEditProfileClick} 
                    onChangePasswordClick={handleChangePasswordClick} 
                    onManagePaymentClick={handleManagePaymentClick}
                    onLogOutClick={handleLogOutClick}
                />
                { renderAlert() }
                { Components[mode]}
                
                <LogoutDialog 
                    open={logoutDialogOpen}
                    onClose={handleLogoutDialogClose}
                    onLogout={handleLogOut}
                />
                <ChangePasswordDialog
                    open={changePasswordDialogOpen}
                    onClose={handleChangePasswordDialogClose}
                />
                <Footer />
            </div>
        );
    }
}

export default Home;