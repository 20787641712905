import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';


export function getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
    );

    return new Promise((resolve, reject) => {
        canvas.toBlob(
            (blob) => {
                blob.name = fileName;
                resolve(blob);
            },
            "image/jpeg",
            1
        );
    });
}

const Crop = ({ src, crop, onChange, onImageLoaded }) => {


    return (
        <ReactCrop
            src={src}
            crop={crop}
            onChange={onChange}
            onImageLoaded={onImageLoaded}
        />
    );
}

export default Crop;