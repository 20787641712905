import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initializeApp } from 'firebase/app';
import './i18n';

const firebaseConfig = {
  apiKey: "AIzaSyD5a93g8ri7X1PnBVh4GMZTh775ThtuUM0",
  authDomain: "brightensport-be-ce942.firebaseapp.com",
  projectId: "brightensport-be-ce942",
  storageBucket: "brightensport-be-ce942.appspot.com",
  messagingSenderId: "220954720076",
  appId: "1:220954720076:web:f43cc6517926bc6f5181e9",
  measurementId: "G-BHKRYX38YE"
};

initializeApp(firebaseConfig);

ReactDOM.render(
  <React.StrictMode>
    {/* Suspense is needed to connect i18n to react. translation files are loaded async, while we wait we render nothing. if want to provide a fallback
    component like loading indicator, use the fallback property */}
    <Suspense fallback={null}>
        <App />
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
