import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    logoutButton: {
        color: theme.palette.primary.darkGrey
    }
}));

const LogoutDialog = ({ open, onClose, onLogout }) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle id="logout-dialog-title">{t('logout_dialog_label_title')}</DialogTitle>
            <DialogContent>
                <Typography>
                    {t('logout_dialog_label_confirmation')}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button className={classes.logoutButton} onClick={onLogout}>
                    <Typography>
                        {t('logout_dialog_button_confirm')}
                    </Typography>
                </Button>
                <Button color="primary" onClick={onClose}>
                    <Typography>
                        {t('logout_dialog_button_cancel')}
                    </Typography>
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default LogoutDialog;