import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { DataProtectionLink, ImpressumLink, TermsLink } from '../constants';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.dark,
        color: "white",
        height: 60,
        left: 0,
        bottom: 0,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",

    },
    footerContent: {
        color: theme.palette.primary.fontColor,
        marginLeft: 10,
        marginRight: 10,
        alignSelf: "center",
        fontSize: 18,
        textTransform: "none",
        textDecoration: "underline",
        '&:hover': {
            backgroundColor: "transparent",
            color: theme.palette.primary.darkGrey,
            textDecoration: "underline",
        }
    },
}));

const Footer = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return(
        <footer className={classes.root}>
            <a className={classes.footerContent} target="_blank" href={ImpressumLink} >{t('footer_impressum')}</a>
            <p className={classes.footerContent}> | </p>
            <a className={classes.footerContent} target="_blank" href={TermsLink}>{t('tc')}</a>
            <p className={classes.footerContent}> | </p>
            <a className={classes.footerContent} target="_blank" href={DataProtectionLink}>{t('footer_data_protection')}</a>
        </footer>
    );
}

export default Footer;