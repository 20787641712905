import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const lngDetector = new LanguageDetector(null, {
    order: ['navigator'],
});

i18n
    .use(initReactI18next)
    .use(Backend)
    .use(lngDetector)
    .init({
        debug: process.env.NODE_ENV === "development",
        nonExplicitSupportedLngs: true,
        fallbackLng: 'en',
        supportedLngs: ["en", "de"],
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: '/locales/{{lng}}/{{ns}}.json',
        }
    });

export default i18n;
