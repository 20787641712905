import videojs from 'video.js';
import { useRef, useEffect } from 'react';
import 'video.js/dist/video-js.css';

const VideoPlayer = ({options, onReady, returnPlayer}) => {
    const videoRef = useRef(null);
    const playerRef = useRef(null);

    useEffect(() => {
        // ensure Video.JS player is initialised only once
        if (!playerRef.current) {
            const videoElement = videoRef.current;
            if (!videoElement) return;

            const player = playerRef.current = videojs(videoElement, options, () => {
                console.log("player is ready");
                onReady && onReady(player);
                returnPlayer && returnPlayer(player);
            });
        } else {
            // can update the player here through props
            // const player = playerRef.curent;
            // player.autoplay(options.autoplay);
            // player.src(options.sources;
        }
    }, [options]);

    // dispose of the player when the functional component unmounts
    useEffect(() => {
        return () => {
            if (playerRef.current) {
                playerRef.current.dispose();
                playerRef.current = null;
            }
        }
    }, []);

    return (
        <div >
            <video ref={videoRef} className="video-js vjs-big-play-centered"/>
        </div>
    );
}

export default VideoPlayer;