import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { VideoSDKMeeting} from '@videosdk.live/rtc-js-prebuilt';
import LoadingScreen from './LoadingScreen';
import axios from 'axios';

import { AuthTokenKey, Forbidden, LoginRoute, SessionsEndpoint } from '../constants';

const VideoChat = () => {
    const history = useHistory();
    const { id } = useParams();
    console.log("video call for session id ", id); 
    const [uiLoading, setUiLoading] = useState(false);
    const [currentUserId, setCurrentUserId] = useState();
    const [session, setSession] = useState();

    useEffect(() => {
        setUiLoading(true);
        const authToken = localStorage.getItem(AuthTokenKey);
        axios.defaults.headers.common = {Authorization: `${authToken}`};
        axios
            .get(`${process.env.REACT_APP_BASE_URL}${SessionsEndpoint}/${id}`)
            .then((response) => {
                const session = response.data;
                setSession(session);
                // TODO: better error handling
                setCurrentUserId(localStorage.getItem('currentUserId'));
                setupVideoSDK(session);
                setUiLoading(false);
            })
            .catch((error) => {
                setUiLoading(false);
                if (error.response && error.response.status === Forbidden) {
                    localStorage.removeItem(AuthTokenKey);
                    history.push(LoginRoute);
                }
                console.error("An error occured occured when retrieving the session: ", error);
            })
    }, [currentUserId]);

    const setupVideoSDK = (session) => {
        const apiKey = process.env.REACT_APP_VIDEOSDK_API_KEY;
        const meetingId = id;
        const name = currentUserId === session.amaId ? `${session.amaFirstName} ${session.amaLastName}` : `${session.proFirstName} ${session.proLastName}`;

        const config = {
        name: name,
        meetingId: meetingId,
        apiKey: apiKey,

        containerId: null,
        redirectOnLeave: "https://app.brightensport.com",

        micEnabled: true,
        webcamEnabled: true,
        participantCanToggleSelfWebcam: true,
        participantCanToggleSelfMic: true,

        chatEnabled: true,
        screenShareEnabled: true,
        pollEnabled: false,
        whiteboardEnabled: false,
        raiseHandEnabled: false,

        recordingEnabled: false,
        recordingEnabledByDefault: false,
        recordingWebhookUrl: "",

        brandingEnabled: true,
        brandLogoURL: "https://i.ibb.co/1sCBXWV/brightensport-iconthick-tuerkis.png",
        brandName: "brightensport",

        participantCanLeave: true, // if false, leave button won't be visible

        livestream: {
            autoStart: false,
            outputs: [
            // {
            //   url: "rtmp://x.rtmp.youtube.com/live2",
            //   streamKey: "<STREAM KEY FROM YOUTUBE>",
            // },
            ],
        },

        permissions: {
            askToJoin: false, // Ask joined participants for entry in meeting
            toggleParticipantMic: false, // Can toggle other participant's mic
            toggleParticipantWebcam: false, // Can toggle other participant's webcam
            removeParticipant: false, // Remove other participant from meeting
            endMeeting: true, // End meeting for all participant
            drawOnWhiteboard: false, // Can Draw on whiteboard
            toggleWhiteboard: false, // Can toggle whiteboard
            toggleRecording: false, // Can toggle recording
        },

        joinScreen: {
            visible: true, // Show the join screen ?
            title: currentUserId === session.amaId ?`Coaching mit ${session.proFirstName} ${session.proLastName}` : `Coaching mit ${session.amaFirstName} ${session.amaLastName}`, // Meeting title
            meetingUrl: window.location.href, // Meeting joining url
        },

        pin: {
            allowed: false, // participant can pin any participant in meeting
            layout: "SPOTLIGHT", // meeting layout - GRID | SPOTLIGHT | SIDEBAR
        },
        };

        const meeting = new VideoSDKMeeting();
        meeting.init(config);
    };

    if (uiLoading) {
        return <LoadingScreen/>
    } else {
        return (<div></div>);
    }
};

export default VideoChat;