import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import * as Constants from '../constants';
import { authMiddleWare } from '../util/Auth';

import ProAthleteProfile from './ProAthleteProfile';
import LoadingScreen from './LoadingScreen';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import axios from 'axios';

const DarkTextTypography = withStyles({
    root: {
        color: "#3A3A3A",
      }
})(Typography);

const useStyles = makeStyles( (theme) => ({
    mainColor: theme.palette.primary.main,
    root: {
        flexGrow: 1,
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    gridContainer: {
        padding: 12,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        backgroundColor: 'white',
    },
    title: {
        textAlign: 'center',
        color: theme.palette.primary.darkGray,
        margin: 30,
    },
    cardTitle: {
        fontWeight: "fontWeightBold",
        color: theme.palette.primary.darkGray,
    },
    sportCard: {
        width: "100%",
        maxWidth: 200,
        maxHeight: "auto",
        borderRadius: 10,
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.highlight,
        },
        textAlign: 'center',
    },
    sportCardSelected: {
        width: "100%",
        maxWidth: 200,
        maxHeight: "auto",
        borderRadius: 10,
        color: theme.palette.primary.main,
        textAlign: 'center',
        backgroundColor: theme.palette.primary.highlight,
    },
    athleteCard: {
        width: "100%",
        maxWidth: 200,
        maxHeight: "auto",
        borderRadius: 10,
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.highlight,
        },
        textAlign: 'center',
    },
    athleteCardActionArea: {

    },
    athleteCardMedia: {
        width: "auto",
        maxHeight: "200px"
    },
    media: {
        width: "50%",
        marginTop: "13%",
        marginLeft: "25%",
        marginRight: "25%",
    },
    uiProgress: {
        position: 'fixed',
        zIndex: '1000',
        height: '31px',
        width: '31px',
        top: '35%'
    },
    priceLabel: {
        marginTop: '-2rem',
        marginLeft: '15px',
        color: '#FF6F58',
        fontSize: '1.5rem',
    },
    cardContainer: {
        paddingLeft: "15%",
        paddingRight: "15%",
        display: "flex",
        justifyContent: "center",
    },
}));
const Overview = ({ onRequestFeedbackClick }) => {

    const { t, i18n } = useTranslation();
    const history = useHistory();
    const classes = useStyles();
    const [athleteProfileOpen, setAthleteProfileOpen] = useState(false);
    const [selectedSportId, setSelectedSportId] = useState(null);
    const [sports, setSports] = useState([]);
    const [allAthletes, setAllAthletes] = useState([]);
    const [displayAthletes, setDisplayAthletes] = useState([]);
    const [selectedAthlete, setSelectedAthlete] = useState(null);
    const [uiLoading, setUiLoading] = useState(false);
    const [currentLanguage] = useState(i18n.language.substring(0,2));

    const prosRef = useRef();

    useEffect( () => {
        authMiddleWare(history);
        const authToken = localStorage.getItem(Constants.AuthTokenKey);
        axios.defaults.headers.common = { Authorization: `${authToken}`};
        setUiLoading(true);
        fetchSports();
        fetchAllAthletes();
    }, []);

    // Backend communication
    /**
     * Fetching all available sports from the server
     */
    const fetchSports = () => {
        axios
            .get(process.env.REACT_APP_BASE_URL + Constants.FetchSportsEndpoint)
            .then( (res) => {
                console.log("fetched sports ", res.data);
                setSports(res.data.filter((sport) => sport.display));
            })
            .catch( (err) => {
                setUiLoading(false);
                if (err.response && err.response.status === Constants.Forbidden) {
                    handleLogOut();
                }
            })
    };

    /**
     * Fetching all available athletes from the server
     */
    const fetchAllAthletes = () => {
        axios
            .get(process.env.REACT_APP_BASE_URL + Constants.FetchAthletesEndpoint)
            .then((res) => {
                setAllAthletes(res.data);
                setDisplayAthletes(res.data);
                setSelectedAthlete(res.data[0]);
                setUiLoading(false);
            })
            .catch( (err) => {
                setUiLoading(false);
                if (err.response && err.response.status === Constants.Forbidden) {
                    handleLogOut();
                }
            })
    };

    // Dialog handling
    const handleAthleteProfileClose = () => {
        setAthleteProfileOpen(false);
    }

    // Click handlers
    /**
     * Handling the click on a sports card and filtering the list of available athletes for this particular sport
     * @param {} sport 
     */
    const handleSportsCardClick = (sport) => {
        setSelectedSportId(sport.id);
        sport.name === "all sports" ? setDisplayAthletes(allAthletes) : 
                                            setDisplayAthletes(allAthletes.filter(athlete => sport.displayName[currentLanguage].toUpperCase() === athlete.sportName[currentLanguage].toUpperCase()));
        prosRef.current.scrollIntoView({ behavior: 'smooth' });
                                            
    };

    /**
     * Handling the click on an athlete card and opening the detailed profile of the athlete
     * @param {} athlete 
     */
    const handleAthleteCardClick = (athlete) => {
        setSelectedAthlete(athlete);
        setAthleteProfileOpen(true);
    };



    // Helper functions
    /**
     * Perform logout: remove the AuthToken from local storage and display the login page
     */
    const handleLogOut = () => {
        localStorage.removeItem(Constants.AuthTokenKey);
        history.push(Constants.LoginRoute);
    };

    if (uiLoading) { 
        return (
            <LoadingScreen/>
        );
    } else {
        return (
            <div className={classes.root}>
                {/* the following div and padding is needed to address Grid limitations: https://material-ui.com/components/grid/#limitations */}
                <div className={classes.gridContainer}>
                    <DarkTextTypography className={classes.title} variant="h5" component="h2">
                        {t('home_screen_label_sports')}
                    </DarkTextTypography>
                    <Grid className={classes.cardContainer} container spacing={3} direction="row" justifyContent="space-around"> 
                        {sports.map((sport, key) => (
                            <Grid key={key} item sm={2}>
                                <Card 
                                    className={sport.id === selectedSportId ? classes.sportCardSelected : classes.sportCard} 
                                    onClick={ () => handleSportsCardClick(sport)}
                                >
                                    <CardActionArea>
                                        <CardMedia
                                            component="img"
                                            image={sport.imagePath}
                                            title="Sports icon"
                                        />
                                        <CardContent>
                                            <DarkTextTypography variant="subtitle1" className={classes.cardTitle}>
                                                {sport.displayName[currentLanguage]}
                                            </DarkTextTypography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>                    
                            </Grid>
                        ))}
                    </Grid>
                    <DarkTextTypography className={classes.title} variant="h5" component="h2">
                            {t('home_screen_label_athletes')}
                    </DarkTextTypography>
                    <Grid className={classes.cardContainer} container spacing={3} justifyContent="space-around" ref={prosRef}>
                        {displayAthletes.map((athlete, key) => (
                            <Grid key={key} item sm={3}>
                                <Card 
                                    className={classes.athleteCard}
                                    onClick={() => handleAthleteCardClick(athlete)}
                                >
                                    <CardActionArea className={classes.athleteCardActionArea}>
                                    {/* enclosing the CardMedia in this div to make sure the cards are of the same size and images centered regardless of image size*/}
                                    {/* https://codesandbox.io/s/n71098vo64?file=/src/pages/index.js:826-1007 */}
                                    <div 
                                        style={{
                                            display: "flex",
                                            alignItem: "center",
                                            justifyContent: "center"
                                        }}
                                    >
                                        <CardMedia 
                                            className={classes.athleteCardMedia}
                                            component="img"
                                            image={athlete.avatar}
                                            title="Profile picture"
                                        />
                                        </div>
                                        <CardContent>
                                            <DarkTextTypography variant="h6" display="block">
                                                {athlete.firstName+ ' ' + athlete.lastName}
                                            </DarkTextTypography>
                                            <DarkTextTypography variant="subtitle1" align="left">
                                                {`${t("general_sport_name")}: ${athlete.sportName[currentLanguage]}`}
                                                <br></br>
                                                {athlete.sessionPrice && `${t("general_session_price")}: ${parseInt(athlete.sessionPrice, 10)}€`}
                                            </DarkTextTypography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </div>
                <ProAthleteProfile 
                    open={athleteProfileOpen} 
                    onClose={handleAthleteProfileClose} 
                    athlete={selectedAthlete}
                    onRequestFeedbackClick={onRequestFeedbackClick} 
                />
            </div>
        );
    }
}

export default Overview;