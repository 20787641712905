import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Toolbar from '@material-ui/core/Toolbar';

import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';

import logo from '../assets/logo_brightensport_white.svg';

const NavButton = withStyles({
    root: {
        boxShadow: 'none',
        textTransform: 'none',
        height: '100%',
        backgroundColor: "transparent",
        fontSize: 20,
        color: "white",
        align: "center",
        padding: '6px 12px',
        '&:hover': {
            color: 'white',
            boxShadow: 'none',
            borderBottom: '0.1rem solid',
            borderRadius: '0px',
            borderColor: '#3A3A3A',
          },
          '&:active': {
            color: 'white',
            boxShadow: 'none',
            borderBottom: '0.1rem solid',
            borderRadius: '0px',
            borderColor: '#3A3A3A',          
          },
          '&:focus': {
            boxShadow: 'none',
            color: 'white',
            borderBottom: '0.1rem solid',
            borderRadius: '0px',
            borderColor: '#BEBEBE',
          },
    }
})(Button);

const useStyles = makeStyles((theme) => ({
    logo: {
        maxWidth: 200,
        width: "100%",
        height: "auto",
        padding: 0,
        '&:hover': {
            backgroundColor: "transparent",
        },
    },
    grow: {
      flexGrow: 1,
    },
    navButtonSelected: {
        borderBottom: '0.1rem solid',
        borderRadius: '0px',
        borderColor: '#3A3A3A',
    },
    menuButtton: {
        textTransform: 'none',
        backgroundColor: "transparent",
        align: "center",
        '&:hover': {
            backgroundColor: "transparent",
          },
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    inputRoot: {
      color: 'inherit',
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  }));

const Nav = ({ currentUser, mode, stripeOnboardingComplete, onLogoClick, onHomeClick, onSessionsClick, onFAQClick, onEditProfileClick, onChangePasswordClick, onManagePaymentClick, onLogOutClick }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [anchorElement, setAnchorElement] = useState(null);
    const [mobileAnchorElement, setMobileAnchorElement] = useState(null);

    const isMenuOpen = Boolean(anchorElement);
    const isMobileMenuOpen = Boolean(mobileAnchorElement);

    const handleProfileMenuOpen = (event) => {
        setAnchorElement(event.currentTarget);
    };

    const handleMobileMenuOpen = (event) => {
        setMobileAnchorElement(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileAnchorElement(null);
    };

    const handleMenuClose = () => {
        setAnchorElement(null);
        handleMobileMenuClose();
    };

    const handleEditProfileClick = () => {
        handleMenuClose();
        onEditProfileClick();
    }

    const handleChangePasswordClick = () => {
        handleMenuClose();
        onChangePasswordClick();
    }

    const handleManagePaymentClick = () => {
        handleMenuClose();
        onManagePaymentClick();
    };

    const handleLogOutClick = () => {
        handleMenuClose();
        onLogOutClick();
    }
    
    const menuId = 'account-menu';
    const renderMenu = (
       <Menu
        anchorEl={anchorElement}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right'}}
        open={isMenuOpen}
        onClose={handleMenuClose}
       >
            <MenuItem onClick={handleEditProfileClick}>{t('nav_bar_menu_edit_profile')}</MenuItem>
            <MenuItem onClick={handleChangePasswordClick}>{t('nav_bar_menu_change_password')}</MenuItem>
            { currentUser && currentUser.userType === "pro" && stripeOnboardingComplete && <MenuItem onClick={handleManagePaymentClick}>{t('nav_bar_menu_manage_payment')}</MenuItem> }
            <MenuItem onClick={handleLogOutClick}>{t('nav_bar_menu_logout')}</MenuItem>
       </Menu>
   );
    console.log("current mode: ", mode);
    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
       <Menu
        anchorEl={mobileAnchorElement}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
       >
            <MenuItem onClick={ () => { handleMenuClose(); onHomeClick();}}>
                <p>Home</p>
            </MenuItem>
            {currentUser.userType === "ama" && <MenuItem onClick={ () => { handleMenuClose(); onSessionsClick(); }}>
                <p>{t('nav_bar_button_my_sessions')}</p>
            </MenuItem>}
            
            <MenuItem onClick={ () => { handleMenuClose(); onFAQClick(); }}>
                <p>FAQ</p>
            </MenuItem>
            <MenuItem onClick={handleProfileMenuOpen}>
                <p>{t('nav_bar_button_settings')}</p>
            </MenuItem>
       </Menu>
   );

    return (
        <div>
            {/*<div className={classes.grow}>*/}
            <AppBar position="static">
                <Toolbar>
                    <Button className={classes.logo} onClick={onLogoClick}>
                        <img className={classes.logo} alt="logo" src={logo} />
                    </Button>
                    <div className={classes.grow}/>
                    <div className={classes.sectionDesktop}>
                        <NavButton
                                className={ mode === 'overview' && classes.navButtonSelected }
                                onClick={onHomeClick}
                                disableElevation
                            >
                                Home
                            </NavButton>
                            { currentUser.userType === "ama" &&  <NavButton
                                className={ mode === 'sessions' && classes.navButtonSelected }
                                onClick={onSessionsClick}
                                disableElevation
                            >
                                {t('nav_bar_button_my_sessions')}
                            </NavButton> }
                               
                        <NavButton
                            className={ mode === 'faq' && classes.navButtonSelected } 
                            onClick={onFAQClick}
                            disableElevation
                        > 
                            FAQs
                        </NavButton>
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
        </div>
    );
}

export default Nav;