import PropTypes from 'prop-types';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import VideoPlayer from './VideoPlayer';
import Canvas from './Canvas';

import * as Constants from '../constants';
import { authMiddleWare } from '../util/Auth';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@mui/icons-material/Edit';

const useStyles = makeStyles( (theme) => ({
    root: {
        maxHeight: "100%",
    },
    content: {
        textAlign: "left",
        marginTop: 10,
        justify: "center",
        margin: "auto",
        width: "80%",
        hieght: window.innerHeight
    },
    progress: {
        position: 'absolute'
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(2),
        color: theme.palette.primary.darkGrey,
      },
    uiProgress: {
        position: 'fixed',
        zIndex: '1000',
        height: '31px',
        width: '31px',
        top: '35%'
    },
    saveButton: {
        textAlign: "center",
        marginTop: '10px',
        
    },
    link: {
        marginTop: 0,
    },
    saveError: {
        color: 'red',
        marginBottom: 10,
    },
    videoCanvasContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    videoCanvasContainerMargin: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '20px'
    },
    videoPlayerLayer: {
        width: '100%',
    },
    canvasLayer: {
        width: '100%',
        marginLeft: '-100%',
        position: 'relative',
        marginBottom: '50px',
    },
    saveSuccess: {
        color: theme.palette.primary.main,
        marginBottom: 10,
    },
    editButtonContainer: {
        direction: 'row', 
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    selected: {
        color: theme.palette.primary.main
    },
}));

const SessionDetailsDialog = ({ currentUser, session, open, onClose }) => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();

    const [uiLoading, setUiLoading] = useState(false);
    const [pro, setPro] = useState(null);
    const [videoCallUrl, setVideoCallUrl] = useState(session.videoCallUrl);
    const [feedbackMessage, setFeedbackMessage] = useState("");
    const [buttonLoading, setButtonLoading] = useState(false);
    const [saveError, setSaveError] = useState(null);
    const [saveSuccess, setSaveSuccess] = useState(false);
    const [player, setPlayer] = useState();
    const [editMode, setEditMode] = useState(false);

    const playerRef = useRef(null);

    const videoJSOptions = {
        autoplay: false,
        controls: true,
        controlBar: {
            fullscreenToggle: false,
        },
        playbackRates: [0.25, 0.5, 1, 1.5],
        width: 700,
        height: 450,
        sources: [{
            src: session.videoUrl,
            type: 'video/mp4'
        }]
    }

    const handlePlayerReady = (player) => {
        playerRef.current = player;
        // handle player events here
        player.on('waiting', () => {
            console.log('player is waiting');
        });

        player.on('timeupdate', () => {
            console.log("current time: %s", player.currentTime());
        });

        player.on('dispose', () => {
            console.log('player will dispose')
        });
    }

    const handleReturnPlayer = (player) => {
        setPlayer(player);
    };

    useEffect( () => {
        let value = "";
        if (currentUser.userType === "ama") {
            value = session.feedbackMessage && session.feedbackMessage.trim() !== "" ? session.feedbackMessage : t("session_details_text_field_no_feedback")
        } else {
            value = session.feedbackMessage ? session.feedbackMessage : ""
        }
        setFeedbackMessage(value);
        setVideoCallUrl(session.videoCallUrl ? session.videoCallUrl : "");
        authMiddleWare(history);
        const authToken = localStorage.getItem(Constants.AuthTokenKey);
        axios.defaults.headers.common = {Authorization: `${authToken}`};
        setUiLoading(true);
        axios
            .get(process.env.REACT_APP_BASE_URL + `/athletes/${session.proId}`)
            .then( (response) => {
                setPro(response.data);

            })
            .catch((err) => {
                console.error(err);
                //TODO: display error to user
            });
    }, [session]);

    const handleChange = (event) => {
        const value = event.target.value;
        switch (event.target.name) {
            case "videoCallUrl":
                setVideoCallUrl(value);
                break;
            case "feedbackMessage":
                setFeedbackMessage(value);
                break;
            default: 
                break;
        }
    };

    // const renderVideoCallUrl = () => {
    //     let returnElement = null;
    //     if (currentUser.userType === "pro") {
    //         returnElement = (
    //             <TextField
    //                 fullWidth
    //                 label={t("session_details_text_field_label_video_call_url")}
    //                 margin="dense"
    //                 name="videoCallUrl"
    //                 variant="outlined"
    //                 onChange={handleChange}
    //                 value={videoCallUrl}
    //         />
    //         );
    //     } else if (currentUser.userType === "ama" && session.videoCallUrl) {
    //         returnElement = (
    //             <Typography
    //                 className={classes.link}
    //             >
    //                 <Link
    //                     className={classes.link}
    //                     href={session.videoCallUrl ? session.videoCallUrl : "#"}
    //                     rel="noopener noreferrer"
    //                     target="_blank"
    //                 >
    //                     {t("session_details_label_video_call")}
    //                 </Link>
    //             </Typography>
    //         );
    //     } else {
    //         returnElement = (
    //             <Typography>
    //                 {t("session_details_label_no_video_call")}
    //             </Typography>
    //         );
    //     }
    //     return returnElement;
    // };

    const renderFeedbackMessage = () => {
        return (
            <TextField
                fullWidth
                label={t("session_details_text_field_label_feedback_message")}
                margin="dense"
                name="feedbackMessage"
                multiline={true}
                rows={5}
                variant="outlined"
                onChange={handleChange}
                disabled={currentUser.userType === "ama"}
                value={feedbackMessage} 
            />
        );
    };

    const handleSaveButtonClick = (event) => {
        event.preventDefault();
        setButtonLoading(true);
        authMiddleWare(history);
        const authToken = localStorage.getItem(Constants.AuthTokenKey);
        axios.defaults.headers.common = {Authorization: `${authToken}`};
        let newSession = {};
        if (videoCallUrl.trim() !== "") {
            // making sure it contains https://, otherwise it will not link to external resource
            const prefix = videoCallUrl.trim().substring(0,4);
            if (prefix !== "http") {
                newSession = { ...newSession, videoCallUrl: 'http://'+videoCallUrl};
            } else {
                newSession = { ...newSession, videoCallUrl: videoCallUrl};
            }
        }
        if (feedbackMessage.trim() !== "") {
            newSession = { ...newSession, feedbackMessage: feedbackMessage};
        }
        axios
            .put(process.env.REACT_APP_BASE_URL + `/sessions/${session.id}`, newSession)
            .then( () => {
                setButtonLoading(false);
                setSaveSuccess(true);
            })
            .catch( (err) => {
                //TODO: handle error
                console.error(err);
                setButtonLoading(false);
                setSaveError(t("session_details_label_error_save"));
            });
    };

    const renderSaveButton = () => {
        let returnElement = null;
        if (currentUser.userType === "pro") {
            returnElement = (
                <Button
                    onClick={handleSaveButtonClick}
                    color="primary"
                    variant="contained"
                    disabled={buttonLoading || videoCallUrl && videoCallUrl.trim() === "" || feedbackMessage && feedbackMessage.trim() === ""}
                >
                    {t("session_details_button_cta")}
                    {buttonLoading && <CircularProgress size={30} className={classes.progress}/> }
                </Button>
            );
        } 
        return returnElement;
    };

    const renderSaveFeedback = () => {
        let returnElement = null;
        if (saveSuccess) {
            returnElement = (
                <Typography className={classes.saveSuccess}>
                    {t("session_details_label_success_save")}
                </Typography>
            );
        } else if (saveError) {
            <Typography className={classes.saveError}>
                    {saveError}
            </Typography>
        }
        return returnElement;
        
    };

    return (
        <Dialog
            className={classes.root}
            onClose={onClose}
            aria-labelledby="session-details"
            open={open}
            fullWidth
            maxWidth="md"
        >
            <DialogTitle
                id="session-details-title"
                className={classes.content}
            >
                { session !== null && (format(parseISO(session.date.start), "PPPP p") + " - " + format(parseISO(session.date.end), "p")) }
            <IconButton
                className={classes.closeButton}
                onClick={onClose}
            >
                <CloseIcon />
            </IconButton>
            </DialogTitle>
            <Divider/>
            <DialogContent>
                <Grid container spacing={3} className={classes.content} direction="column">
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            disabled={true}
                            variant="outlined"
                            label={currentUser.userType === "ama" ? t("session_details_text_field_label_coach_name") : t("session_details_text_field_label_requester_name")}
                            value={currentUser.userType === "ama" ? `${session.proFirstName} ${session.proLastName}` : `${session.amaFirstName} ${session.amaLastName}`}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <TextField
                            fullWidth
                            label={t("session_details_text_field_label_sport_name")}
                            disabled={true}
                            variant="outlined"
                            value={session.sports}
                        />
                    </Grid>
                    <Grid item md={12}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                window.open(`/sessions/${session.id}/call`)
                                localStorage.setItem('currentUserId', currentUser.id);
                            }}
                        >
                            { t("sesssion_details_button_join_call") }
                        </Button>
                    </Grid>
                    { session.videoUrl && 
                        <Grid item container className={classes.editButtonContainer}>
                            <Typography>
                                {t("session_details_label_uploaded_video")}
                            </Typography>
                        
                            {currentUser.userType === "pro" && 
                                    <IconButton aria-label="edit-mode" className={editMode && classes.selected} onClick={() => setEditMode(!editMode)}>
                                        <EditIcon/>
                                    </IconButton>}
                        </Grid>
                    }
                    
                    <Grid item xs={12} >
                        { session.videoUrl &&   
                            <div className={editMode ? classes.videoCanvasContainerMargin : classes.videoCanvasContainer}>
                                <div className={classes.videoPlayerLayer}>
                                    <VideoPlayer
                                        className={classes.layer1}
                                        options={videoJSOptions}
                                        onReady={handlePlayerReady}
                                        returnPlayer={handleReturnPlayer}
                                        onClick={() => console.log("video clicked")}
                                    />
                                </div>
                                <div className={classes.canvasLayer}>
                                    <Canvas
                                        className={classes.layer2}
                                        options={{width: "700", height: "410"}}
                                        player={player}
                                        editMode={editMode}
                                    />
                                </div>
                            </div>
                        }
                        
                    </Grid>
                    <Grid item xs={12} >
                        <TextField
                            fullWidth
                            label={currentUser.userType === "ama" ? t("session_details_text_field_label_ama_message") : t("session_details_text_field_label_pro_message")}
                            disabled={true}
                            multiline={true}
                            rows={5}
                            variant="outlined"
                            value={session.customMessage}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        {renderFeedbackMessage()}
                    </Grid>
                    <Grid item xs={12} >
                        {renderSaveFeedback()}
                        {renderSaveButton()}
                    </Grid>
                </Grid>
            </DialogContent>
         
        </Dialog>
    );
};

SessionDetailsDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    session: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
};

export default SessionDetailsDialog;
