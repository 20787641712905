import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import * as Constants from '../constants';
import { authMiddleWare } from "../util/Auth";

import { makeStyles, withStyles } from "@material-ui/styles";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import axios from 'axios';
import { format, parseISO } from 'date-fns';

import SessionDetailsDialog from "./SessionDetailsDialog";
import LoadingScreen from "./LoadingScreen";

const DarkTextTypography = withStyles({
    root: {
        color: "#3A3A3A",
      }
})(Typography);

const useStyles = makeStyles( (theme) => ({
    root: {
        flexGrow: 1, 
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    gridContainer: {
        paddingLeft: 30,
        paddingRight: 30,
        paddingBottom: 30,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        backgroundColor: 'white',
    },
    title: {
        textAlign: 'center',
        color: theme.palette.primary.darkGray,
        margin: 30,
    },
    cardTitle: {
        fontWeight: 'fontWeightBold',
        color: theme.palette.primary.darkGray,
        textAlign: 'left',
    },
    card: {
        width: '100%',
        maxHeight: 'auto',
        color: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.highlight,
        },
        textAlign: 'left',
    },
    link: {
        color: theme.palette.primary.darkGrey,
        marginLeft: 10,
        marginRight: 10,
        alignSelf: "center",
        fontSize: 18,
        textTransform: "none",
        textDecoration: "none",
        '&:hover': {
            backgroundColor: "transparent",
            color: theme.palette.primary.mediumGrey,
            textDecoration: "none",
        }
    },
    uiProgress: {
        position: 'fixed',
        zIndex: '1000',
        height: '31px',
        width: '31px',
        top: '35%',
    },
}));

const Sessions = ({ currentUser }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const [sessions, setSessions] = useState([]);
    const [selectedSession, setSelectedSession] = useState(null);
    const [sessionDetailsOpen, setSessionDetailsOpen] = useState(false);
    const [uiLoading, setUiLoading] = useState(false);

    useEffect( () => {
        fetchSessions();
    }, []);

    const fetchSessions = () => {
        authMiddleWare(history);
        setUiLoading(true);
        const authToken = localStorage.getItem(Constants.AuthTokenKey);
        axios.defaults.headers.common = {Authorization: `${authToken}`};
        axios
            .get(process.env.REACT_APP_BASE_URL + Constants.SessionsEndpoint)
            .then( (response) => {
                console.log(response.data);
                var sessionsToDisplay = response.data.filter(session => {
                    if (session.paymentCompleted === undefined) {
                        return true;
                    } else if (session.paymentCompleted === true) {
                        return true;
                    }
                    return false;
                });
                setSessions(sessionsToDisplay);
                setUiLoading(false);
            })
            .catch( (err) => {
                setUiLoading(false);
                if (err.response && err.response.status === Constants.Forbidden) {
                    handleLogOut();
                }
            })
    };

    const handleCardClick = (session) => {
        setSelectedSession(session);
        console.log("selected session: ", session);
        setSessionDetailsOpen(true);
    };

    const handleSessionDetailsClose = () => {
        setSessionDetailsOpen(false);
    };

    const handleLogOut = () => {
        localStorage.removeItem(Constants.AuthTokenKey);
        history.push(Constants.LoginRoute);
    };

    const renderWelcome = () => {
        let returnElement = null;
        if (sessions.length === 0) {
            returnElement = (
                <Link className={classes.link} href="/">{t(`sessions_label_title_${currentUser.userType}_no_sessions`)}</Link>
            );  
        } else {
            returnElement = (
                t("sessions_label_title")
            );
        }
        return returnElement;
    };

    if (uiLoading) {
        return (
            <LoadingScreen/>
        );
    } else {
        return (
            <div className={classes.root}>
                <DarkTextTypography className={classes.title} variant="h5" component="h2">
                    {renderWelcome()}
                </DarkTextTypography>
               {/* the following div and padding is needed to address Grid limitations: https://material-ui.com/components/grid/#limitations */}
                <div className={classes.gridContainer}>
                    <Grid container spacing={3} justify="center" alignItems="center">
                        {sessions.map( (session, key) => (
                                <Grid key={key} item xs={8} sm={5}>
                                    <Card className={classes.card} onClick={() => handleCardClick(session)}>
                                        <CardActionArea>
                                            <CardContent>
                                                <DarkTextTypography className={classes.cardTitle} variant="subtitle2" gutterBottom>
                                                    { `${format(parseISO(session.date.start), "p")} - ${format(parseISO(session.date.end), "p PPP")}`}
                                                </DarkTextTypography>
                                                <Divider />
                                                <br />
                                                <DarkTextTypography >
                                                    { `${t("sessions_card_sports")}: ${session.sports}`}
                                                </DarkTextTypography>
                                                <DarkTextTypography variant="subtitle1">
                                                    {currentUser.userType === "ama" ? 
                                                        `${t("sessions_card_coach")}: ${session.proFirstName} ${session.proLastName}` : 
                                                        `${t("sessions_card_requester")}: ${session.amaFirstName} ${session.amaLastName}`}
                                                </DarkTextTypography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                        ))}
                    </Grid>
                </div>
                { selectedSession !== null && <SessionDetailsDialog
                    open={sessionDetailsOpen}
                    onClose={handleSessionDetailsClose}
                    session={selectedSession}
                    currentUser={currentUser}
                /> }
                
            </div>
        );
    };
}

Sessions.propTypes = {
    currentUser: PropTypes.object.isRequired,
};

export default Sessions;