import { Button, Card, CircularProgress, Container, makeStyles, TextField, Typography } from "@material-ui/core";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getAuth, sendPasswordResetEmail, connectAuthEmulator } from 'firebase/auth';
import { isEmail } from "../../util/validators";

import { AuthEmulatorUrl, ForgotPasswordSuccessRoute } from "../../constants";
import brightensportLogo from '../../assets/logo_brightensport_tuerkis.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        minHeight: window.innerHeight,
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center'
    },
    paper: {
        margin: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    card: {
        borderRadius: 10
    },
    label: {
        marginTop: theme.spacing(1),
        textAlign: 'center',
    },
    labelBold: {
        marginTop: theme.spacing(1),
        fontWeight: 'bold',
        textAlign: 'center',
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    customError: {
        color: 'red',
        fontSize: '0.8rem',
        marginTop: 10,
        textAlign: 'center',
    },
    progress: {
        position: 'absolute'
    },
    logo: {
        display: "flex",
        alignItem: "center",
        justifyContent: "center",
        alignSelf: "center",
        maxWidth: "40%",
        marginBottom: 10,
    }
}));

const ForgotPassword = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleChange = (event) => {
        if (event.target.name === "email") {
            setEmail(event.target.value);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (!isEmail(email)) {
            setErrors({email: t("forgot_password_error_email_invalid")});
            return;
        }
        const auth = getAuth();
        console.log(process.env.NODE_ENV);
        if (process.env.NODE_ENV === "development") {
            connectAuthEmulator(auth, AuthEmulatorUrl);
        }
        setLoading(true);
        sendPasswordResetEmail(auth, email.trim())
            .then((data) => {
                console.log(data);
                setLoading(false);
                history.push(ForgotPasswordSuccessRoute);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                setErrors({general: t("general_error")});
            });
    };

    return (
        <Container className={classes.root} component="main" maxWidth="xs">
            <Card className={classes.card}>
                <div className={classes.paper}>
                    <img className={classes.logo} alt="brightensport logo" src={brightensportLogo}/>
                    <Typography className={classes.labelBold} variant="body1">
                        {t("forgot_password_label_title")}
                    </Typography>
                    <Typography className={classes.label} variant="body2" align="center">
                        {t("forgot_password_label_description")}
                    </Typography>
                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            name="email"
                            label={t("forgot_password_text_field_label_email")}
                            autoFocus
                            helperText={errors.email}
                            error={errors.email ? true : false}
                            onChange={handleChange}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={handleSubmit}
                            disabled={loading || !email}
                        >
                            {t("forgot_password_cta")}
                            {loading && <CircularProgress size={30} className={classes.progress}/>}
                        </Button>
                        {errors.general && 
                                            <Typography variant="body2" className={classes.customError}>
                                                {errors.general}
                                            </Typography>
                        }
                    </form>
                </div>
            </Card>
        </Container>
    );
};

export default ForgotPassword;