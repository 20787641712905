import { makeStyles, withStyles } from "@material-ui/styles";
import Typography from '@material-ui/core/Typography';

const DarkTextTypography = withStyles({
    root: {
        color: '#3A3A3A',
    },
})(Typography);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(3),
        width: '85%', 
        margin: 'auto',
        marginTop: 20,
    },
    nested: {
        paddingLeft: theme.spacing(4),
        color: theme.palette.primary.mediumGrey,
    },
}));

const faqs = [
    {
        question: "Wie funktioniert die brightensport-Plattform?",
        answer: "brightensport bietet Dir die Möglichkeit, von einer:m Spitzensportler:in Videoanalysen und -coaching zu bekommen. Dazu meldest Du Dich einfach auf der Plattform an, suchst Dir Deine:n Coach aus, lädst Dein Video hoch und wählst einen Termin. In einem Videoanruf gibt er oder sie Dir dann Feedback und beantwortet Deine Fragen."
    },
    {
        question: "Wie finde ich eine:n Spitzensportler:in als Coach?",
        answer: "Sobald Du Dich auf der Plattform angemeldet hast, siehst Du eine Übersicht aller Spitzensportler:innen, die Du nach Sportarten filtern kannst. \n\n Solltest Du Deine Sportart nicht finden oder weitere Athleth:innen kennen, die Du gerne auf der Plattform sehen würdest, dann teile uns das gerne über feedback@brightensport.com mit!"
    },
    {
        question: "Wie buche ich eine Session?",
        answer: "Um eine Session zu buchen, gehst Du folgendermaßen vor: Auf brightensport einloggen Deine Sportart auswählen Eine:n Spitzensportler:in aussuchen \“Feedback anfragen\” anklicken Im Kalender einen Slot auswählen Dein Video hochladen Eine Nachricht mit spezifischen Fragen anfügen Die Buchung bestätigen",
    },
    {
        question: "Wie lade ich ein Video hoch?",
        answer: "Im Buchungsprozess einer Feedback-Session (s. “Wie buche ich eine Session”) erscheint nach der Wahl des Termins der Upload-Dialog. Folgenden Formate werden akzeptiert: *.mp4. *.mov, *.flv",
    },
    {
        question: "Ich kann zu der gebuchten Zeit nun doch nicht - kann ich eine Session absagen oder verschieben?",
        answer: "Im Moment ist es noch nicht möglich, eine gebuchte Session abzusagen oder zu verschieben. Sollte dieser Fall eintreten, wende Dich bitte über support@brightensport.com an uns.",
    },
    {
        question: "Wie ändere ich mein Passwort?",
        answer: "Das Passwort lässt sich ändern, indem Du auf Dein Profilbild oben rechts klickst und den Anweisungen unter “Passwort ändern” folgst.",
    },
    {
        question: "Ich habe mein Passwort vergessen -  was nun?",
        answer: "Solltest Du dein Passwort vergessen haben schreibe uns bitte eine Mail an support@brightensport.com",
    },
    {
        question: "Wie nehme ich am besten ein Video auf?",
        answer: "Grundsätzlich gilt: Je mehr verschiedene Ansichten ein:e Coach zur Verfügung hat, desto besser lassen sich Dinge erkennen. Denk bitte daran, dass alle relevanten Körperteile und Bewegungen sichtbar sein sollten. Außerdem sollte Dein Körper als Faustregel mindestens ein Drittel des Bildschirms einnehmen. Die Kamera eines modernen Smartphones dürfte ausreichend hohe Qualität liefern. Eine zweite Person kann Dich ganz einfach über eine längere Strecke in der richtigen Perspektive aufnehmen. Falls gerade niemand in der Nähe ist, um Dich zu filmen, verwendest Du am besten ein Stativ. Natürlich kannst Du aber auch anders für eine passende Perspektive sorgen.",
    },
    {
        question: "An wen kann ich mich wenden, wenn ich Fragen habe?",
        answer: "Du erreichst uns für allgemeine Fragen am besten unter support@brightensport.com.",
    },
    {
        question: "Wie kann ich Feedback zur Plattform geben, Bugs melden oder mir Features wünschen?",
        answer: "Mit solchen Themen wendest Du Dich am besten unter feedback@brightensport.com an uns.",
    },
    {
        question: "Wann kommen welche weiteren Sportarten dazu?",
        answer: "Nach und nach werden wir weitere Sportarten ins Programm aufnehmen. Du wünschst Dir eine spezielle Sportart oder kennst Athlet:innen, die gerne als Profi auf der Plattform aktiv wären? Dann schreib uns gerne eine Mail an feedback@brightensport.com!",
    },
    {
        question: "Wie kann eine:n Spitzensportler:in entlohnen, von der oder dem ich während der Testphase Feedback bekommen habe?",
        answer: "Wenn er oder sie im Profil einen PayPal.me-Link angegeben hat, kannst Du direkt auf diesem Weg einen Betrag Deiner Wahl senden. Ist kein solcher Link angegeben, wende Dich bitte über support@brightensport.com an uns und wir finden gemeinsam eine Lösung.",
    },
    {
        question: "Wie geht es weiter mit brightensport?",
        answer: "Wir werden Dein Feedback aus der aktuellen Testphase implementieren und die Plattform um neue Features erweitern, die bereits in der Pipeline sind. Mit dem so weiterentwickelten Produkt wird es dann eine größere Beta-Testphase geben, für die sich auch Fremde registrieren können. Weitere Informationen dazu bekommst Du natürlich rechtzeitig von uns. ",
    },
    {
        question: "Wieso wird aktuell nur die Sportart Laufen angeboten?",
        answer: "Um möglichst schnell möglichst vielen Leuten zu helfen, ihre Technik zu verbessern, haben wir uns für die Sportart entschieden, die von den meisten Menschen betrieben wird.",
    },
    {
        question: "Wer steckt eigentlich hinter brightensport?",
        answer: "Wir sind ein Team aus drei Gründern mit verschiedenen Hintergründen: iurie ist Software-Entwickler und erfahrener Startup-Gründer. Früher war er semi-professioneller Kickboxer, heute verbringt er den Großteil seiner Zeit mit Klettern und Alpinismus. Sebastian war schon VC-Investor, Company Builder, Innovationsberater, Gründer und Entwicklungshelfer. Im Winter findet man ihn auf dem Snowboard oder auf Skitour, im Sommer beim Surfen oder Klettern. Louis hat als Eishockeytorhüter die klassische Leistungssport-Laufbahn mit Nachwuchsnationalmannschaft und Sporthilfe durchlaufen. Nach dem Studium war er als Unternehmensberater tätig.",
    },
    {
        question: "Wie viel wird später eine Coaching-Session kosten?",
        answer: "Alle Spitzensportler:innen setzen ihre eigenen Preise fest. Wir haben darauf keinen Einfluss.",
    },
    {
        question: "Verdienen Spitzensportler:innen eigentlich nicht schon genug Geld?",
        answer: "Das ist leider ein weit verbreiteter Irrglaube. Geblendet von den Gehältern einiger weniger Superstars vergisst man leicht die vielen Spitzensportler:innen in weniger bekannten Sportarten. Die meisten Leistungssportler:innen können von ihrem Sport nicht oder nur gerade so ihren Lebensunterhalt finanzieren. Ihnen wollen wir mit brightensport eine neue Einkommensquelle eröffnen. Dir gibt das zusätzlich das gute Gefühl, den Spitzensport zu unterstützen.",
    },
    {
        question: "Ich kenne eine:n Spitzensportler:in. Wie kann ich sie oder ihn für brightensport gewinnen?",
        answer: "Dafür gibt es zwei Optionen: Entweder Du machst eine Intro bzw. gibst ihr/ihm einfach unsere Kontaktdaten (am besten profis@brightensport.com) oder Du fragst uns nach Infomaterial, leitest es ihr/ihm weiter und sie/er kann sich direkt an uns wenden.",
    },
    {
        question: "Wie häufig sollte ich für maximalen Fortschritt eine Session buchen?",
        answer: "Das stimmst Du am besten individuell mit deiner/m Coach ab.",
    }
];

const FAQ = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {faqs.map((object, key) => (
                <>
                    <DarkTextTypography variant="h5">
                        {key + 1}. {object.question}
                    </DarkTextTypography>
                    <DarkTextTypography className={classes.nested} variant="h6">
                        {object.answer}
                    </DarkTextTypography>
                </>
            ))}
        </div>
    );
}

export default FAQ;
