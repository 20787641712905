import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { useEffect, useState } from 'react';
import parseISO from 'date-fns/parseISO'; 

const useStyles = makeStyles( (theme) => ({
    avatar: {
        display: "flex",
        alignItem: "center",
        justifyContent: "center",
        alignSelf: "center",
        maxWidth: "75%",
        marginBottom: 10,

    },
    dialogStyle: {
        maxHeight: "100%",
    },
    content: {
        textAlign: "left",
        marginLeft: 50,
        marginRight: 50,
        marginBottom: 10,
    },
    subtitle: {
        textAlign: "left",
        marginLeft: 50,
        marginRight: 50,
        color: 'gray',
        marginBottom: 10,
        fontWeight: 'bold',
    },
    cta: {
        display: "block",
        backgroundColor: theme.palette.primary.dark,
        color: "white",
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.darkGrey,
        },
        textAlign: "center",
    },
}));


const ProAthleteProfile = ({ athlete, onRequestFeedbackClick, open, onClose }) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    const [hasAvailableSlots, setHasAvailableSlots] = useState(false);
    const [currentLanguage] = useState(i18n.language.substring(0,2));

    useEffect( () => {
        if (!athlete) {
            return;
        }
        const availableFutureSlots = athlete.availableSlots.filter( slot => {
            const startDate = parseISO(slot.start);
            const currentDate = new Date();
            if (startDate < currentDate) {
                return false;
            }
            return true;
        });
        console.log("available future slots: ", availableFutureSlots);
        if (availableFutureSlots.length > 0) {
            setHasAvailableSlots(true);
        } else {
            setHasAvailableSlots(false);
        }
    }, [athlete]);

    const getCTA = () => {
        if (!athlete) {
            return;
        }
        if (!athlete.sessionPrice) {
            return t('athlete_profile_dialog_label_pro_not_available');
        }

        if (!hasAvailableSlots) {
            return t('athlete_profile_dialog_no_slots');
        }
        return t('athlete_profile_dialog_button_cta');
    }

    return(
        <Dialog
            className={classes.dialogStyle}
            onClose={onClose}
            aria-labelledby="pro-athlete-profile"
            open={open}
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle 
                className={classes.content}
                id="pro-athlete-profile-title"
            >
                {athlete !== null && (athlete.firstName + ' ' +  athlete.lastName)}
            </DialogTitle>
            <img className={classes.avatar} alt="Profile pic" src={athlete !== null && athlete.avatar}/>
            <Typography className={classes.subtitle}>
                {`${t("general_sport_name")}: ${ athlete !== null && athlete.sportName[currentLanguage]}`}
                <br/>
                {athlete !== null && athlete.sessionPrice && `${t("general_session_price")}: ${parseInt(athlete.sessionPrice, 10)}€`}
            </Typography>
            <br/>
            <Typography className={classes.content}>
                {athlete !== null && athlete.description}
            </Typography>
            <br/>
            <Typography className={classes.content}>
                {athlete !== null && athlete.credentials}
            </Typography>
            <br/>

            <Button 
                className={classes.cta}
                disabled={athlete !== null && !athlete.sessionPrice || !hasAvailableSlots }
                onClick={() => onRequestFeedbackClick(athlete)}
            >
                <Typography>
                    { athlete !== null && getCTA()}
                </Typography>
            </Button>
        </Dialog>
    );
};


ProAthleteProfile.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    athlete: PropTypes.object,
    onRequestFeedbackClick: PropTypes.func,
};

export default ProAthleteProfile;