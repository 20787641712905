import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Card, CircularProgress, Container, TextField, Typography} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import brightensportLogo from '../assets/logo_brightensport_tuerkis.svg';
import axios from 'axios';
import { AuthTokenKey, Forbidden, LoginEndpoint } from '../constants';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        minHeight: window.innerHeight,
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center'
    },
    forgotButtonContainer: {
        flexGrow: 1,
        minWidth: '100%',
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'flex-end',
    },
    signupButtonContainer: {
        flexGrow: 1,
        minWidth: '100%',
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'center',
    },
    paper: {
        margin: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    card: {
        borderRadius: 10
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1)
    },
    labelBold: {
        marginTop: theme.spacing(1),
        fontWeight: 'bold'
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    customError: {
        color: 'red',
        fontSize: '0.8rem',
        marginTop: 10,
        textAlign: 'center',
    },
    progress: {
        position: 'absolute'
    },
    logo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        maxWidth: '40%',
        marginBottom: 10,
    },
}));

const Login = () => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleChange = (event) => {
        if (event.target.name === "email") {
            setEmail(event.target.value);
        } else if (event.target.name === "password") {
            setPassword(event.target.value);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const userData = {
            email: email.trim(),
            password: password.trim()
        };
        let loginURL = process.env.REACT_APP_BASE_URL + LoginEndpoint;
        axios
            .post(loginURL, userData)
            .then( (response) => {
                localStorage.setItem(AuthTokenKey, `Bearer ${response.data.token}`);
                setLoading(false);
                history.push("/");
            })
            .catch( (error) => {
                console.error(error);
                if (error.response && error.response.status  === Forbidden) {
                    console.log("auth error");
                    setErrors({ general: t("login_error_wrong_password") })
                } else {
                    setErrors({general: t("general_error") });
                }
                setLoading(false);
            })
    }

    return (
        <Container className={classes.root} component="main" maxWidth="xs">
            <Card className={classes.card}>
                <div className={classes.paper}>
                    <img className={classes.logo} alt="brightensport logo" src={brightensportLogo}/>
                    <Typography className={classes.labelBold} variant="body1" >
                        {t("login_title")}
                    </Typography>
                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            name="email"
                            autoComplete="email"
                            label={t("login_text_field_label_email")}
                            autoFocus
                            helperText={errors.email}
                            error={errors.email ? true : false}
                            onChange={handleChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="password"
                            name="password"
                            type="password"
                            autoComplete="current-password"
                            label={t("login_text_field_label_password")}
                            helperText={errors.password}
                            error={errors.password ? true : false}
                            onChange={handleChange}
                        />
                        <div className={classes.forgotButtonContainer}>
                            <Button
                                variant="text"
                                color="primary"
                                size="small"
                                onClick={ ()=> history.push('/forgot-password')}
                            >
                                {t("login_button_forgot_password")}
                            </Button>
                        </div>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={handleSubmit}
                            disabled={ loading || !email || !password }
                        >
                            {t("login_button_cta")}
                            {loading && <CircularProgress size={30} className={classes.progress}/>}
                        </Button>
                        {errors.general && 
                                    <Typography variant="body2" className={classes.customError}>
                                        {errors.general}
                                    </Typography>}
                        <div className={classes.signupButtonContainer}>
                            <Button
                                variant="text"
                                color="primary"
                                size="small"
                                onClick={ () => history.push('/user-signup')}
                            >
                                {t('login_button_signup')}
                            </Button>
                        </div>
                    </form>
                </div>
            </Card>
        </Container>
    );
};

export default Login;