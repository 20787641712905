import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Button, Grid, TextField, Typography, CircularProgress } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import axios from 'axios';
import { authMiddleWare } from '../util/Auth';
import * as Constants from '../constants';
import clsx from 'clsx';

const useStyles = makeStyles( (theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        mingWidth: '50%', 
        marginLeft: '12%',
    },
    root: {},
    details: {
        display: 'flex'
    },
    avatar: {
        height: 110,
        width: 110,
        flexShrink: 0,
        flexGrow: 0
    },
    locationText: {
        paddingLeft: '15px'
    },
    buttonProperty: {
        position: 'absolute',
        top: '50%'
    },
    grid: {
        width: '50%',
    },
    uiProgress: {
        position: 'fixed',
        zIndex: '1000',
        height: '31px',
        width: '31px',
        left: '50%',
        top: '35%'
    },
    progress: {
        position: 'absolute'
    },
    passwordError: {
        color: 'red',
        margin: 12,
    },
    successMessage: {
        color: theme.palette.primary.main,
        margin: 12,
    },
    uploadButton: {
        marginLeft: '8px',
        margin: theme.spacing(1)
    },
    customError: {
        color: 'red',
        fontSize: '0.8rem',
        marginTop: 10
    },
    submitButton: {
        textAlign: "center",
        marginTop: '10px',
        marginRight: '10px'
    }
}));

const ChangePasswordDialog = ({ open, onClose }) => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [uiLoading, setUiLoading] = useState(true);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [passwordError, setPasswordError] = useState('');
    const [success, setSuccess] = useState(false);

    useEffect( () => {
        authMiddleWare(history);
        const authToken = localStorage.getItem(Constants.AuthTokenKey);
        axios.defaults.headers.common = {Authorization: `${authToken}`};
        setUiLoading(false);

    }, []);

    const handleChange = (event) => {
        const value = event.target.value;
        switch (event.target.name) {
            case "currentPassword":
                setCurrentPassword(value);
                break;
            case "newPassword":
                setNewPassword(value);
                break;
            case "repeatPassword":
                setRepeatPassword(value);
                break;
            default:
                break;
        };
    };

    const updateFormValues = (event) => {
        event.preventDefault();
        if (newPassword.trim() !== repeatPassword.trim()) {
            setPasswordError(t("change_password_dialog_error_match"));
            return;
        }
        setButtonLoading(true);
        authMiddleWare(history);
        const authToken = localStorage.getItem(Constants.AuthTokenKey);
        axios.defaults.headers.common = {Authorization: `${authToken}`};
        const formRequest = {
            currentPassword: currentPassword,
            newPassword: newPassword
        };
        console.log("form request:", formRequest);
        axios
            .put(process.env.REACT_APP_BASE_URL + '/user/password', formRequest)
            .then(() => {
                setButtonLoading(false);
                setPasswordError('');
                setSuccess(true);
                console.log("password change succeeded");
            })
            .catch((err) => {
                setButtonLoading(false);
                if (err.response && err.response.status === 420) {
                    setPasswordError(t("change_password_dialog_error_wrong_password"));
                }
                if (err.response && err.response.status === 403) {
                    localStorage.removeItem(Constants.AuthTokenKey);
                    history.push(Constants.LoginRoute);
                }
                console.log("errror when changing pwd: ", err.response.status);
            });
    };

    const renderResult = () => {
        let message;
        let returnElement = null;
        if (passwordError !== "") {
            returnElement = <Typography className={classes.passwordError} variant="subtitle2"> {passwordError} </Typography>
        }
        if (success) {
            returnElement = <Typography className={classes.successMessage} variant="subtitle2"> {t("change_password_dialog_success")} </Typography>
        }
        return returnElement;
    };

    return (
        <main className={classes.content}>
            <Dialog open={open} onClose={onClose}>
            <DialogTitle> {t("change_password_dialog_label_title")} </DialogTitle>
            <DialogContent>
                <Card className={clsx(classes.root, classes)}>
                    <form autoComplete="off" noValidate>
                        <CardContent>
                            <Grid container spacing={3} direction="column">
                            <Grid item xs={12} >
                                    <TextField
                                        fullWidth
                                        label={t("change_password_dialog_text_field_current_password")}
                                        margin="dense"
                                        name="currentPassword"
                                        type="password"
                                        variant="outlined"
                                        value={currentPassword}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t("change_password_dialog_text_field_new_password")}
                                        margin="dense"
                                        name="newPassword"
                                        type="password"
                                        variant="outlined"
                                        value={newPassword}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        label={t("change_password_dialog_text_field_repeat_password")}
                                        margin="dense"
                                        name="repeatPassword"
                                        type="password"
                                        variant="outlined"
                                        value={repeatPassword}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </form>
                </Card>
                {renderResult()}
            </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        className={classes.submitButton}
                        onClick={ (event) => updateFormValues(event)}
                        disabled={
                            buttonLoading || currentPassword.trim() === "" || newPassword.trim() === "" || repeatPassword.trim() === ""
                        }
                    >
                        {t("change_password_dialog_button_cta")}
                        {buttonLoading && <CircularProgress size={30} className={classes.progress}/>}
                    </Button>
                </DialogActions>
            </Dialog>
        </main>
    );
}

export default ChangePasswordDialog;