import { Card, CircularProgress, Container, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import brightensportLogo from '../assets/logo_brightensport_tuerkis.svg';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        minHeight: window.innerHeight,
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center'
    },
    paper: {
        margin: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    card: {
        borderRadius: 10,
        height:'250px',
    },
    label: {
        marginTop: theme.spacing(1),
    },
    progress: {
        color: theme.palette.primary.main,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        position: 'relative',
        left: '-100px',
    },
    progressBackground: {
        color: theme.palette.primary.lightGrey,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        marginLeft: '100px',
    },
    progressContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    logo: {
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        maxWidth: '40%',
        marginBottom: 10,
    },
}));

const LoadingScreen = () => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Container className={classes.root} component="main" maxWidth="xs">
            <Card className={classes.card}>
                <div className={classes.paper}>
                    <img className={classes.logo} alt="brightensport logo" src={brightensportLogo}/>
                    <div className={classes.progressContainer}>
                        <CircularProgress className={classes.progressBackground} variant="determinate" value={100}  size={100} thickness={2}/>                    
                        <CircularProgress className={classes.progress} disableShrink size={100} thickness={2}/>                    
                    </div>
                    <Typography className={classes.label} variant="body2" align="center">
                        Loading...
                    </Typography>
                </div>
            </Card>
        </Container>
    );
};

export default LoadingScreen;