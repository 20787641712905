import { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Card, Checkbox, CircularProgress, Container, FormControlLabel, MenuItem, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';

import brightensportLogo from '../assets/logo_brightensport_tuerkis.svg';
import { AuthTokenKey, SignupEndoint, TermsLink } from '../constants';
import { isEmail, isValidPassword } from '../util/validators';
import { FetchSportsEndpoint} from '../constants';
import LoadingScreen from './LoadingScreen';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        minHeight: window.innerHeight,
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center'
    },
    loginButtonContainer: {
        flexGrow: 1,
        minWidth: '100%',
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'flex-end',
    },
    paper: {
        margin: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    card: {
        borderRadius: 10
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1)
    },
    labelBold: {
        marginTop: theme.spacing(1),
        fontWeight: 'bold'
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    customError: {
        color: 'red',
        fontSize: '0.8rem',
        marginTop: 10,
        textAlign: 'center',
    },
    progress: {
        position: 'absolute'
    },
    logo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        maxWidth: '40%',
        marginBottom: 10,
    },
    checkBoxLinks: {
        color: theme.palette.primary.main,
        alignSelf: "center",
        textTransform: "none",
        textDecoration: "underline",
        '&:hover': {
            backgroundColor: "transparent",
            color: theme.palette.primary.darkGrey,
            textDecoration: "underline",
        }
    },
    textField: {
        color: theme.palette.primary.main,
    }
}));

const Signup = () => {
    const classes = useStyles();
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const location = useLocation();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tcCheckboxChecked, setTcCheckBoxChecked] = useState(false);
    const [ageConsentCheckboxChecked, setAgeConsentCheckboxChecked] = useState(false);
    const [inputValid, setInputValid] = useState(false);
    const [userType, setUserType] = useState('');
    const [uiLoading, setUiLoading] = useState(false);
    const [availableSports, setAvailableSports] = useState([]);
    const [sportName, setSportName] = useState('');
    const [currentLanguage ] = useState([i18n.language.substring(0,2)]);

    useEffect(() => {
        let type;
        location.pathname === "/pro-signup" ? type = "pro" : type = "ama";
        setUserType(type);
        if (type === "pro") {
            setUiLoading(true);
            axios
                .get(`${process.env.REACT_APP_BASE_URL}${FetchSportsEndpoint}`)
                .then((response) => {
                    setAvailableSports(response.data.filter((sport) => sport.name !== "all sports"));
                    console.log("available sports: ", response.data);
                    setUiLoading(false);
                })
                .catch((error) => {
                    setUiLoading(false);
                    setErrors({ general: t("general_error") });
                });
        }
    }, []);

    const handleChange = (event) => {
        const value = event.target.value;
        setErrors([]);
        switch (event.target.name) {
            case "firstName":
                setFirstName(value);
                if (value.trim() === "") {
                    setInputValid(false);
                }
                break;
            case "lastName":
                setLastName(value);
                if (value.trim() === "") {
                    setInputValid(false);
                }
                break;
            case "email":
                setEmail(value);
                if (!isEmail(value)) {
                    setInputValid(false);
                    setErrors({ email: t("forgot_password_error_email_invalid")});
                } else {
                    setInputValid(true);
                }
                break;
            case "sportName": {
                console.log("sport selected: ", value);
                let selectedSportName = availableSports.filter(sport => sport.displayName[currentLanguage] === value)[0].displayName;
                console.log(selectedSportName);
                setSportName(selectedSportName);
                break;
            }
            case "password":
                setPassword(value);
                if (!isValidPassword(value)) {
                    setInputValid(false);
                    setErrors({ password: t("reset_password_label_description")});
                }  else if (repeatPassword.trim() !== "" && repeatPassword !== value) {
                    setInputValid(false);
                    setErrors({ repeatPassword: t("reset_password_error_password_match")});
                } else {
                    setInputValid(true);
                }
                break;
            case "repeatPassword":
                setRepeatPassword(value);
                if (value !== password) {
                    setInputValid(false);
                    setErrors({ repeatPassword: t("reset_password_error_password_match")});
                } else {
                    setInputValid(true);
                }
                break;
            default:
                console.log("Unhandled option");
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        let userData = {
            firstName: firstName,
            lastName: lastName,
            email: email,
            userType: userType,
            password: password,
            confirmPassword: repeatPassword
        };
        if (userType === "pro") {
            userData = {...userData, sportName: sportName};
        }
        console.log("user data: ", userData);
        axios
            .post(`${process.env.REACT_APP_BASE_URL}${SignupEndoint}`, userData)
            .then((response) => {
                localStorage.setItem(AuthTokenKey, `Bearer ${response.data.token}`);
                setLoading(false);
                history.push('/');
            })
            .catch((error) => {
                console.log("auth error");
                setErrors({ general: t("general_error")});
                setLoading(false);
            });
    }

    const handleTCChange = (event) => {
        setTcCheckBoxChecked(event.target.checked);
    }

    const handleAgeConsentChange = (event) => {
        console.log("age checkbox checked: ", event.target.checked);
        setAgeConsentCheckboxChecked(event.target.checked);
    }

    const isInputValid = () => {
        const isStandardInputValid = inputValid && firstName.trim() !== "" && lastName.trim() !== "" && tcCheckboxChecked && ageConsentCheckboxChecked;
        
        if (userType === "ama") {
            return isStandardInputValid;
        } else {
            return isStandardInputValid && sportName[currentLanguage].trim() !== "";
        }
    }

    if (uiLoading) {
        return (
            <LoadingScreen/>
        );
    } else {
        return (
            <Container className={classes.root} component="main" maxWidth="xs">
                <Card className={classes.card}>
                    <div className={classes.paper}>
                        <img className={classes.logo} alt="brightensport logo" src={brightensportLogo}/>
                        <Typography className={classes.labelBold} variant="body1">
                            { t("signup_title") }
                        </Typography>
                        <form className={classes.form} noValidate>
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="firstName"
                                name="firstName"
                                label={t("signup_label_first_name")}
                                autoFocus
                                helperText={errors.firstName}
                                error={errors.firstName ? true : false}
                                onChange={handleChange}
                            />
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="lastName"
                                name="lastName"
                                label={t("signup_label_last_name")}
                                helperText={errors.lastName}
                                error={errors.lastName ? true : false}
                                onChange={handleChange}
                            />
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                name="email"
                                label={t("signup_label_email")}
                                helperText={errors.email}
                                error={errors.email ? true : false}
                                onChange={handleChange}
                            />
                            { userType === "pro" && 
                                <TextField
                                    className={classes.textField}
                                    select
                                    label={t("general_sport_name")}
                                    fullWidth
                                    required
                                    id="sportName"
                                    name="sportName"
                                    variant="outlined"
                                    onChange={handleChange}
                                >
                                    {availableSports.map((option) => (
                                        <MenuItem key={option.displayName[currentLanguage]} value={option.displayName[currentLanguage]}>
                                            {option.displayName[currentLanguage]}
                                        </MenuItem>
                                    ))}
                                </TextField> 
                            }
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="password"
                                name="password"
                                type="password"
                                label={t("signup_label_password")}
                                helperText={errors.password}
                                error={errors.password ? true : false}
                                onChange={handleChange}
                            />
                            <TextField
                                className={classes.textField}
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="repeatPassword"
                                name="repeatPassword"
                                type="password"
                                label={t("signup_label_repeat_password")}
                                helperText={errors.repeatPassword}
                                error={errors.repeatPassword ? true : false}
                                onChange={handleChange}
                            />
                            <div className={classes.loginButtonContainer}>
                                <Button
                                        variant="text"
                                        color="primary"
                                        size="small"
                                        onClick={ ()=> history.push('/login')}
                                    >
                                        {t("signup_login_button")}
                                    </Button>
                            </div>
                            <FormControlLabel control={<Checkbox onChange={handleTCChange} color="primary"/>} label={
                                <div>
                                    <span> { t("tc_part_one") } </span>
                                    <a className={classes.checkBoxLinks} target="_blank" href={TermsLink}>{t('tc')}</a>
                                    <span> { t("tc_part_two") } </span>
                                </div>
                            }/>
                            <FormControlLabel
                                control={<Checkbox onChange={handleAgeConsentChange} color="primary"/>}
                                label={t("signup_label_age")}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                onClick={handleSubmit}
                                disabled={ loading || !isInputValid()}
                            >
                                {t("signup_cta")}
                                {loading && <CircularProgress size={30} className={classes.progress}/>}
                            </Button>
                        </form>         
                    </div>
                </Card>
            </Container>
        );
    }
  };

export default Signup;