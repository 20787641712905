import { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Card, CircularProgress, Container, makeStyles, TextField, Typography } from '@material-ui/core';
import { getAuth, confirmPasswordReset, connectAuthEmulator } from '@firebase/auth';
import { isValidPassword } from '../../util/validators';
import { AuthEmulatorUrl, ResetPasswordSuccessRoute } from '../../constants';
import brightensportLogo from '../../assets/logo_brightensport_tuerkis.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        minHeight: window.innerHeight,
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center'
    },
    paper: {
        margin: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    card: {
        borderRadius: 10
    },
    labelBold: {
        marginTop: theme.spacing(1),
        fontWeight: 'bold',
        textAlign: 'center',
    },
    label: {
        marginTop: theme.spacing(1),
        textAlign: 'center',
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2)
    },
    customError: {
        color: 'red',
        fontSize: '0.8rem',
        marginTop: 10,
        textAlign: 'center',
    },
    progress: {
        position: 'absolute'
    },
    logo: {
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        maxWidth: '40%',
        marginBottom: 10,
    }
}));

const ResetPassword = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const [newPassword, setNewPassword] = useState();
    const [repeatNewPassword, setRepeatNewPassword] = useState();
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);

    // retrieving the query parameters
    const search = useLocation().search;
    const code = new URLSearchParams(search).get('oobCode');

    const handleChange = (event) => {
        if (event.target.name === "new-password") {
            setNewPassword(event.target.value);
        } else if (event.target.name === "repeat-new-password") {
            setRepeatNewPassword(event.target.value);
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log("Submit button clicked");

        if (!isValidPassword(newPassword)) {
            setErrors({ newPassword: t("reset_password_error_password_strength") });
            return;
        } else if (repeatNewPassword !== newPassword) {
            setErrors({ repeatNewPassword: t("reset_password_error_password_match") });
            return;
        }
        const auth = getAuth();
        if (process.env.NODE_ENV === "development") {
            connectAuthEmulator(auth, AuthEmulatorUrl);
        }
        confirmPasswordReset(auth, code, newPassword)
            .then(() => {
                console.log("password reset was successfull with new pwd: %s", newPassword);
                history.push(ResetPasswordSuccessRoute);
            })
            .catch((error) => {
                console.error(error);
                switch(error.code) {
                    case "auth/expired-action-code":
                        console.log("action code expired");
                        setErrors({ general: t("reset_password_error_token_expired") });
                        break;
                    case "auth/invalid-action-code":
                        console.log("action code is invalid");
                        setErrors({ general: t("general_error") });
                        break;
                    default:
                        setErrors({ general: t("general_error") });
                } 
            });
    };

    return (
        <Container className={classes.root} component="main" maxWidth="xs">
                <Card className={classes.card}>
                    <div className={classes.paper}>
                            <img className={classes.logo} alt="brightensport logo" src={brightensportLogo}/>
                            <Typography className={classes.labelBold} variant="body1">
                                {t("reset_password_label_title")}
                            </Typography>
                            <Typography className={classes.label} variant="body2">
                                {t("reset_password_label_description")}
                            </Typography>
                            <form className={classes.form} noValidate>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="new-password"
                                    name="new-password"
                                    type="password"
                                    label={t("reset_password_text_field_label_new_password")}
                                    autoFocus
                                    helperText={errors.newPassword}
                                    error={errors.newPassword ? true : false }
                                    onChange={handleChange}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="repeat-new-password"
                                    name="repeat-new-password"
                                    type="password"
                                    label={t("reset_password_text_field_label_repeat_new_password")}
                                    helperText={errors.repeatNewPassword}
                                    error={errors.repeatNewPassword ? true : false}
                                    onChange={handleChange}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={handleSubmit}
                                    disabled={loading || !newPassword || !repeatNewPassword}
                                >
                                    {t("reset_password_cta")}
                                    {loading && <CircularProgress size={30} className={classes.progress}/>}
                                </Button>
                                {errors.general && 
                                                <Typography variant="body2" className={classes.customError}>
                                                    {errors.general}    
                                                </Typography>} 
                            </form>   
                    </div>   
                </Card> 
        </Container>
    );
}

export default ResetPassword;