import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, CircularProgress, Container, Grid, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import brightensportLogo from '../../assets/logo_brightensport_tuerkis.svg';
import { AuthTokenKey, HomeRoute, UserAccountLink } from '../../constants';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        minHeight: window.innerHeight,
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
    },
    paper: {
        margin: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    card: {
        borderRadius: 10,
    },
    labelBold: {
        marginTop: theme.spacing(1),
        fontWeight: 'bold',
        textAlign: 'center',
    },
    label: {
        marginTop: theme.spacing(1),
        textAlign: 'center',
   },
   logo: {
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        maxWidth: '40%',
        marginBottom: 10,
   },
   customError: {
        color: 'red',
        fontSize: '0.8rem',
        marginTop: 10,
        textAlign: 'center',
   },
   cancelButton: {
        color: theme.palette.primary.darkGrey,
   },
   progress: {
        position: 'absolute'
    },
   buttonContainer: {
        margin: theme.spacing(3),
        justifyContent: 'space-evenly',
   },
}));

const StripeRefresh = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleCancelClick = () => {
        history.push(HomeRoute);
    };

    const handleRetryClick = () => {
        console.log("retrying the stripe connect bs");
        setLoading(true);
        const authToken = localStorage.getItem(AuthTokenKey);
        axios.defaults.headers.common = { Authorization: `${authToken}`};
        axios
            .get(process.env.REACT_APP_BASE_URL + UserAccountLink)
            .then((response) => {
                console.log(response);
                setLoading(false);
                window.open(response.data.url, "_self");
            })
            .catch((error) => {
                console.error("An error occured when fetching account link: ", error);
                setLoading(false);
                setErrors({ general: t("general_error") });
            })
    };

    return (
        <Container className={classes.root} component='main' maxWidth='xs'>
            <Card className={classes.card}>
                <div className={classes.paper}>
                    <img className={classes.logo} alt="brightensport logo" src={brightensportLogo}/>
                    <Typography className={classes.labelBold} variant="body1">
                        {t("stripe_refresh_label_title")}
                    </Typography>
                    <Typography className={classes.label} variant="body2">
                        {t("stripe_refresh_label_body")}
                    </Typography>
                    <Grid className={classes.buttonContainer} container>
                        <Button
                            className={classes.cancelButton}
                            variant="text"
                            onClick={handleCancelClick}
                        >
                            {t("stripe_refresh_button_cancel")}
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleRetryClick}
                            disabled={loading}
                        >
                            {t("stripe_refresh_button_retry")}
                            {loading && <CircularProgress className={classes.progress} size={30}/> }
                        </Button>
                    </Grid>
                    { errors.general &&
                                    <Typography className={classes.customError} variant="body2">
                                        {errors.general}
                                    </Typography>
                    }
                </div>
            </Card>
        </Container>
    );
};

export default StripeRefresh;