import { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { authMiddleWare } from '../util/Auth';
import * as Constants from '../constants';

import axios from 'axios';
import{ format, parseISO } from 'date-fns';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import deLocale from '@fullcalendar/core/locales/de';
import interactionPlugin from '@fullcalendar/interaction';
import { getStorage, ref, uploadBytes, connectStorageEmulator, getDownloadURL } from 'firebase/storage';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import LoadingScreen from './LoadingScreen';
import { Button, CircularProgress, Checkbox, FormControlLabel, TextField, Typography, IconButton } from '@material-ui/core';
import ClearIcon from '@mui/icons-material/Clear';

const DarkTextTypography = withStyles({
    root: {
        color: "#3A3A3A",
      }
})(Typography);

const ThemeTextTypography = withStyles({
    root: {
        color: "#239985",
        fontSize: 20
      },
})(Typography);

const useStyles = makeStyles( (theme) => ({
    mainColor: theme.palette.primary.main,
    root: {
        flexGrow: 1,
        width:'75%',
        height:'50%',
        marginLeft:'12%',
        padding: theme.spacing(3),
        marginBottom: 30,
    },
    progressContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    calendarContainer: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    mobileCalendarContainer: {
        display: 'block',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    gridContainer: {
        padding: 12,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        backgroundColor: 'white',
    },
    title: {
        textAlign: 'center',
        color: theme.palette.primary.darkGray,
        margin: 30,
    },
    submitButton: {
        textAlign: "center",
        marginTop: 20,
    },
    stepTitle: {
        marginTop: 30,
    },
    uploadButton: {
        marginLeft: '8px',
        margin: theme.spacing(1)
    },
    customError: {
        color: "red",
    },
    uiProgress: {
        position: 'fixed',
        zIndex: '1000',
        height: '31px',
        width: '31px',
        top: '35%'
    },
    emailReceiptFormControl: {
    }
}));

const FeedbackRequest = ({ pro, handleSessionSubmit }) => {
    const classes = useStyles();
    const history = useHistory();
    const { t } = useTranslation();

    const stepTwoRef = useRef();
    const stepThreeRef = useRef();
    const fileInputRef = useRef();

    const [currentUser, setCurrentUser] = useState(null);
    const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
    const [stepOneDone, setStepOneDone] = useState(false);
    const [video, setVideo] = useState(null);
    const [customMessage, setCustomMessage] = useState('');
    const [uiLoading, setUiLoading] = useState(false);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [fileError, setFileError] = useState('');
    const [feedbackRequestError, setFeedbackRequestError] = useState('');
    const [fileName, setFileName] = useState(null);
    const [errors, setErrors] = useState([]);
    const [sendEmailReceipt, setSendEmailReceipt] = useState(false);
    const [videoConsentBoxChecked, setVideoConsentBoxChecked] = useState(false);

    useEffect( () => {

        const fetchUser = () => {
            authMiddleWare(history);
            setUiLoading(true);
            const authToken = localStorage.getItem(Constants.AuthTokenKey);
            axios.defaults.headers.common = {Authorization: `${authToken}`};
            axios
                .get(process.env.REACT_APP_BASE_URL + Constants.UserEndpoint)
                .then( (response) => {
                    setCurrentUser(response.data.userCredentials);
                    setUiLoading(false);
                })
                .catch( (err) => {
                    if (err.response && err.response.status === Constants.Forbidden) {
                        history.push(Constants.LoginRoute);
                    }
                    setErrors({ error: err.code });
                });
        };
        
        fetchUser();
    }, []);

    // TODO: move this somewhere else, coe reuse
    const handleLogOut = () => {
        localStorage.removeItem(Constants.AuthTokenKey);
        history.push(Constants.LoginRoute);
    };

    const handleEventClick = (clickInfo) => {
        let event = {
            id: clickInfo.event.id,
            start: clickInfo.event.start,
            end: clickInfo.event.end
        }
        setSelectedTimeSlot(event);
        setStepOneDone(true);
        stepTwoRef.current.scrollIntoView({behavior: 'smooth'});
    };

    const handleChange = (event) => {
        if (event.target.name === 'customMessage') {
            setCustomMessage(event.target.value);
        }
    }

    const handleReceiptCheckboxChange = (event) => {
        console.log("checkbox checked: ", event.target.checked);
        setSendEmailReceipt(event.target.checked);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const extension = file.name.split('.')[file.name.split('.').length - 1].toLowerCase();
        // TODO: refactor this shit
        if (extension !== "mov" && extension !== "mp4" && extension !== "flv" && extension !== "hevc" && extension !== "heif") {
            setFileError(t("feedback_request_error_video_format"));
            return;
        }
        const currentDate = new Date();
        const fileName = currentUser.id + "-" + currentDate.toISOString() + "." + extension;
        setFileName(fileName);
        setVideo(event.target.files[0]);
        setFileError('');

    }

    const handleFeedbackRequest = (event) => {
        if (customMessage === "") {
            return;
        }
        if (video && !videoConsentBoxChecked) {
            setFileError(t("feedback_requet_error_video_consent"));
            return;
        }
        const newSession = {
            amaId: currentUser.id,
            amaFirstName: currentUser.firstName,
            amaLastName: currentUser.lastName,
            amaEmail: currentUser.email,
            proId: pro.id,
            proFirstName: pro.firstName,
            proLastName: pro.lastName,
            proEmail: pro.email,
            customMessage: customMessage,
            sports: pro.sportName,
            date: selectedTimeSlot,
            emailReceipt: sendEmailReceipt
        }
        const authToken = localStorage.getItem(Constants.AuthTokenKey);
        axios.defaults.headers.common = {Authorization: `${authToken}`};

        setButtonLoading(true);
        axios
            .post(process.env.REACT_APP_BASE_URL + Constants.CreateSessionCheckout, newSession)
            .then( (response) => {
                if (video) {
                    uploadVideo(response.data.sessionId);
                }
                window.open(response.data.url, "_self");
            })
            .catch( (err) => {
                setButtonLoading(false);
                if (err.response && err.response.status === Constants.Forbidden) {
                    handleLogOut();
                }
                setErrors({ error: err.code });
                setFeedbackRequestError(t("general_error"));
            })
    };

    const handleVideoCheckboxChange = (event) => {
        setVideoConsentBoxChecked(event.target.checked);
    };

    const handleClearVideoClick = (event) => {
        setVideo(null);
        setFileName(null);
        fileInputRef.current.value = "";
        setFileError('');
    }

    const uploadVideo = (sessionId) => {
        setButtonLoading(true);
        const storage = getStorage();
        if (process.env.NODE_ENV === "development") {
            connectStorageEmulator(storage, "localhost", 9199);
        }
        const storageRef = ref(storage, `user_videos/${fileName}`);
        uploadBytes(storageRef, video)
            .then( (snapshot) => {
                return getDownloadURL(snapshot.ref);
            })
            .then( (url) => {
                authMiddleWare(history);
                const authToken = localStorage.getItem(Constants.AuthTokenKey);
                axios.defaults.headers.common = {Authorization: `${authToken}`};
                const payload = {
                    videoUrl: url
                }
                return axios.put(process.env.REACT_APP_BASE_URL + `/sessions/${sessionId}`, payload);  
            })
            .then( (response) => {
                setButtonLoading(false);
                handleSessionSubmit();
            })
            .catch( (err) => {
                setButtonLoading(false);
                setFileError(t("general_error"));
                if (err.response && err.response.status === Constants.Forbidden) {
                    handleLogOut();
                }
                setVideo(null);
                console.error(err);
            });
    };

    const renderFirstStep = () => {
        return (
            <div>
                <div className={classes.calendarContainer}>
                    <DarkTextTypography className={classes.stepTitle} variant="h5">
                        {t("feedback_request_label_step_one")}
                    </DarkTextTypography>
                    <br />
                    <FullCalendar 
                        plugins={[ timeGridPlugin, interactionPlugin ]}
                        initialView='timeGridWeek' 
                        locale={deLocale}
                        editable={true}
                        firstDay='1'
                        slotMinTime='06:00:00'
                        slotMaxTime='23:00:00'
                        eventClick={handleEventClick}
                        events={pro.availableSlots.filter( slot => {
                            const startDate = parseISO(slot.start);
                            const currentDate = new Date();
                            if (startDate < currentDate) {
                                return false;
                            }
                            return true;
                        })}
                        eventColor='#44D4BC'
                    /> 
                    <br />
                    {selectedTimeSlot !== null && < ThemeTextTypography className={classes.timeSlotConfirmation}>
                        {`${t("feedback_request_label_selected_slot")} ${format(selectedTimeSlot.start, "p")} - ${format(selectedTimeSlot.end, "p PPP")}`}
                    </ThemeTextTypography>
                    }
                </div>
                <div className={classes.mobileCalendarContainer}>
                    <DarkTextTypography className={classes.stepTitle} variant="h5">
                        {t("feedback_request_label_step_one")}
                    </DarkTextTypography>
                    <br />
                    <FullCalendar 
                        plugins={[ timeGridPlugin, interactionPlugin ]}
                        headerToolbar={{
                            left: 'title',
                            center: '',
                            right: 'prev,next today',
                          }}
                        initialView='timeGridDay' 
                        locale={deLocale}
                        editable={true}
                        firstDay='1'
                        slotMinTime='06:00:00'
                        slotMaxTime='23:00:00'
                        eventClick={handleEventClick}
                        events={pro.availableSlots.filter( slot => {
                            const startDate = parseISO(slot.start);
                            const currentDate = new Date();
                            if (startDate < currentDate) {
                                return false;
                            }
                            return true;
                        })}
                        eventColor='#44D4BC'
                        height='50em'
                    /> 
                    <br />
                    {selectedTimeSlot !== null && < ThemeTextTypography className={classes.timeSlotConfirmation}>
                        {`${t("feedback_request_label_selected_slot")}: ${format(selectedTimeSlot.start, "p")} - ${format(selectedTimeSlot.end, "p PPP")}`}
                    </ThemeTextTypography>
                    }
                </div>
        </div>
        );
    };

    const renderSecondStep = () => {
        return (
            <>
                <DarkTextTypography className={classes.stepTitle} variant="h5" ref={stepTwoRef}>
                    {t("feedback_request_label_step_two")}
                </DarkTextTypography>
                <div>
                <input type="file"ref={fileInputRef} onChange={(event) => handleFileChange(event)} />
                <IconButton aria-label="clearVideo" onClick={handleClearVideoClick} size="small">
                    <ClearIcon/>
                </IconButton>
                <br/>
                {video && <FormControlLabel
                    control={<Checkbox onChange={handleVideoCheckboxChange} color="primary"/>}
                    label= {
                        <Typography variant="body2">
                            {t('feedback_request_label_video_consent')}
                        </Typography>
                    }
                />}
                {fileError ? (
                    <Typography variant="body2" className={classes.customError}>
                        {' '}
                        {fileError}
                    </Typography>
                ) : ( false )}
                </div>
            </>
        );
    };

    const renderThirdStep = () => {
        return (
            <div> 
            <DarkTextTypography className={classes.stepTitle} variant="h5" ref={stepThreeRef}>
                {t("feedback_request_label_step_three")}
            </DarkTextTypography>
            <TextField
                fullWidth
                multiline={true}
                required
                margin='dense'
                name='customMessage'
                mandatory={true}
                label={t("feedback_request_text_field_label_message")}
                rows={3}
                onChange={handleChange}
            />
        </div>
        );
    };

    if (uiLoading) {
        return (
            <LoadingScreen/>
        );
    } else {
        return (
            <div className={classes.root}>
                { renderFirstStep() }
                { stepOneDone && renderSecondStep() }
                { stepOneDone && renderThirdStep() }
                <FormControlLabel 
                    control={<Checkbox onChange={handleReceiptCheckboxChange} color="primary"/>}  
                    label={ 
                        <Typography variant="body1">
                            {t("feedback_request_label_email_receipt")}
                        </Typography>
                    }
                    className={classes.emailReceiptFormControl}
                />
                <br/>
                <Button
                        color='primary'
                        variant='contained'
                        disabled={ selectedTimeSlot === null || customMessage.trim() === "" || buttonLoading}
                        className={classes.submitButton}
                        onClick={handleFeedbackRequest}
                    >
                        {t("feedback_request_button_cta")}
                        {buttonLoading && <CircularProgress size={20} classname={classes.uiProgress}/>}
                    </Button>
                    {feedbackRequestError ? (
                        <Typography className={classes.customError}>
                            {' '}
                            {feedbackRequestError}
                        </Typography>
                    ) : (false)}
                </div>
            );
        }
    }

    

export default FeedbackRequest;