import * as Constants from './constants';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import Home from './components/Home';
import Login from './components/Login';
import ResetPassword from './components/password-reset/ResetPassword';
import ResetPasswordSuccess from './components/password-reset/ResetPasswordSuccess';

import './App.css';
import ForgotPassword from './components/password-reset/ForgotPassword';
import ForgotPaasswordSuccess from './components/password-reset/ForgotPasswordSuccess';
import StripeReturn from './components/stripe/StripeReturn';
import StripeRefresh from './components/stripe/StripeRefresh';
import StripeCheckoutSuccess from './components/stripe/StripeCheckoutSuccess';
import StripeCheckoutFailure from './components/stripe/StripeCheckoutFailure';
import VideoChat from './components/VideoChat';
import Signup from './components/Signup';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: '#ADEDE2',
      highlight: '#dcfaf5',
      main: '#44D4BC',
      dark: '#239985',
      contrastText: '#fff',
      darkGrey: '#3A3A3A',
      mediumGrey: '#BEBEBE',
      lightGrey: '#E7E7E7',
      fontColor: "white",
    }
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
});

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route exact path={Constants.HomeRoute} component={Home}/>
          <Route exact path={Constants.LoginRoute} component={Login}/>
          <Route exact path={Constants.ForgotPasswordRoute} component={ForgotPassword}/>
          <Route exact path={Constants.ForgotPasswordSuccessRoute} component={ForgotPaasswordSuccess}/>
          <Route exact path={Constants.ResetPasswordRoute} component={ResetPassword}/>
          <Route exact path={Constants.ResetPasswordSuccessRoute} component={ResetPasswordSuccess}/>
          <Route exact path={Constants.StripeReturnUrl} component={StripeReturn}/>
          <Route exact path={Constants.StripeRefreshUrl} component={StripeRefresh}/>
          <Route exact path={Constants.StripeCheckoutFailure} component={StripeCheckoutFailure}/>
          <Route exact path={Constants.StripeCheckoutSuccess} component={StripeCheckoutSuccess}/>
          <Route path={Constants.VideoChatRoute} component={VideoChat}/>
          <Route path={Constants.ProSignupRoute} component={Signup}/>
          <Route path={Constants.AmaSignupRoute} component={Signup}/>
        </Switch>
      </Router>
    </MuiThemeProvider>
  );
}

export default App;
