// Generic
// HTTP status
export const Forbidden = 403;

export const HomeRoute = '/';
export const LoginRoute = '/login';
export const FAQRoute = '/faq';
export const ProfileRoute= '/profile';
export const ProSignupRoute = '/pro-signup';
export const AmaSignupRoute = '/user-signup';
export const VideoChatRoute = '/sessions/:id/call';
export const ResetPasswordRoute = '/reset-password';
export const ResetPasswordSuccessRoute = '/reset-password/success';
export const ForgotPasswordRoute = '/forgot-password';
export const ForgotPasswordSuccessRoute = "/forgot-password/success";
export const StripeReturnUrl = '/stripe-connect/return';
export const StripeRefreshUrl = '/stripe-connect/refresh';
export const StripeCheckoutSuccess = '/stripe-checkout/success';
export const StripeCheckoutFailure = '/stripe-checkout/failure';

// Endpoints
export const AuthTokenKey = 'AuthToken';
export const LoginEndpoint = '/login';
export const SignupEndoint = '/signup';
export const FetchSportsEndpoint = '/sports';
export const FetchAthletesEndpoint = '/athletes';
export const UserEndpoint = '/user';
export const SessionsEndpoint = '/sessions';
export const ZoomVideoSDKSignature = '/zoom/signature';
export const UserAccountLink = '/user/stripe/account-link';
export const StripeUserAccount = '/user/stripe/account';
export const StripeConnectLoginLinkEndpoint = '/user/stripe/login-link';
export const CreateSessionCheckout = '/sessions/checkout';

// Links
export const FaqLink = 'https://brightensport.com/#fragen_title';
export const ImpressumLink = 'https://brightensport.com/impressum/';
export const TermsLink = 'https://brightensport.com/agb/';
export const DataProtectionLink = 'https://brightensport.com/datenschutz/';

// Misc
export const AuthEmulatorUrl = 'http://localhost:4000/auth';
